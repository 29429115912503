import { HtmlNodeModel, HtmlNode } from '@logicflow/core';
// import { HtmlResize } from "@logicflow/extension";
import "../../../uml.css";
// import $ from 'jquery'
import img_pre from "../../../assets/title_pre.png";
import img_nor from "../../../assets/title_nor.png";
import { sendUpdate, add_Operation } from "../../../tools/send.js";

// import mindMap from '../../../assets/markmap.svg'
import { zhHansLanguage } from "../../../lang/zh-Hans.js";
import { zhHantLanguage } from "../../../lang/zh-Hant.js";
import { enLanguage } from "../../../lang/en.js";
import { ElMessageBox as MessageBox } from "element-plus";

class ThinkingCardModel extends HtmlNodeModel {
  initNodeData(data) {
    super.initNodeData(data);
    this.text.editable = false; // 禁止节点文本编辑
    // 设置节点宽高和鼠标移动多少距离后开始缩放节点
    const width =
      data.properties.nodeSize && data.properties.nodeSize.width
        ? data.properties.nodeSize.width
        : 250;
    const height =
      data.properties.nodeSize && data.properties.nodeSize.height
        ? data.properties.nodeSize.height
        : 250;
    this.width = width;
    this.height = height;
    this.minWidth = 250;
    this.minHeight = 250;
    this.gridSize = 1;
    const unable_to_connect_with_oneself = {
      message:
        window.location.href.indexOf("cocorobo.cn") != -1
          ? zhHansLanguage.tip43
          : window.location.href.indexOf("cocorobo.hk") != -1
          ? zhHantLanguage.tip43
          : enLanguage.tip43,
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceAnchor, targetAnchor);
        return targetNode.id != sourceNode.id;
      },
    };
    this.sourceRules.push(unable_to_connect_with_oneself);
    const unable_to_connect_with_theme = {
      message:
        window.location.href.indexOf("cocorobo.cn") != -1
          ? zhHansLanguage.unable_to_connect_with_theme
          : window.location.href.indexOf("cocorobo.hk") != -1
          ? zhHantLanguage.unable_to_connect_with_theme
          : enLanguage.unable_to_connect_with_theme,
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceNode, sourceAnchor, targetAnchor);
        return targetNode.type != "icon-theme";
      },
    };
    this.sourceRules.push(unable_to_connect_with_theme);
    const unable_to_connect_with_other = {
      message:
        window.location.href.indexOf("cocorobo.cn") != -1
          ? zhHansLanguage.unable_to_connect_with_other
          : window.location.href.indexOf("cocorobo.hk") != -1
          ? zhHantLanguage.unable_to_connect_with_other
          : enLanguage.unable_to_connect_with_other,
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceAnchor, targetAnchor);
        let edges = this.graphModel.getNodeOutgoingEdge(sourceNode.id);
        let isConnect = false;
        for (let i = 0; i < edges.length; i++) {
          if (
            edges[i].sourceNodeId == sourceNode.id &&
            edges[i].targetNodeId == targetNode.id
          ) {
            isConnect = true;
            break;
          }
        }
        return isConnect == false;
      },
    };
    this.sourceRules.push(unable_to_connect_with_other);
    // this.minWidth = 60;
    // this.minHeight = 60;
    // this.gridSize = 1;
    // 设置锚点
    // this.anchorsOffset = [
    //   [width / 2, 0],
    //   [0, height / 2],
    //   [-width / 2, 0],
    //   [0, -height / 2],
    // ]
  }
  // getResizeOutlineStyle(){
  //   const style = super.getResizeOutlineStyle();
  //   style.stroke = "#007BFF";
  //   style.strokeWidth = 2
  //   style.strokeDasharray = "3,3"
  //   style.fill = "transparent"
  //   return style;
  // }
  setAttributes() {
    if (
      (this.properties.nodeSize &&
        this.properties.nodeSize.height &&
        parseInt(this.properties.nodeSize.height) == 250) ||
      (this.properties.nodeSize &&
        this.properties.nodeSize.width &&
        parseInt(this.properties.nodeSize.width) == 250)
    ) {
      this.width = 250;
      this.height = 250;
    }
  }
  getData() {
    const data = super.getData();
    let properties = data.properties;
    properties = {
      ...properties,
      nodeSize: {
        width: this.width,
        height: this.height,
      }
    }
    return {
      ...super.getData(),
      properties: properties
    }
  }
}
class ThinkingCardNode extends HtmlNode {
  contentTitle(eleDom, textTips, lf, isShowDescribe) {
    const itemDom = document.createElement("div"); //增加提个DOM p标签，用于展示 自定义title
    itemDom.setAttribute("class", "robotHeaderBar-Value"); //添加class命名，用于设置自定义title样式
    // itemDom.style = "border: 1px solid #ffffff;left:" + (eleDom.getBoundingClientRect().width + 10) + "px;top:" + (eleDom.getBoundingClientRect().height - 40) + "px;";
    itemDom.style = "border: 1px solid #ffffff;left:104%;top:80%;";
    const div = document.createElement("div");
    div.style =
      "background: #FFFFFF;width:30px;height:30px;display: flex;flex-direction: row;align-items: center;justify-content: center;";

    const img = document.createElement("img");
    img.src = img_nor;
    div.appendChild(img);
    itemDom.appendChild(div);

    eleDom.parentNode.style = "overflow: visible;";
    // let itemDomShow = false; //用于判断是否添加了节点
    let itemClickDomShow = isShowDescribe;
    const p = document.createElement("p");
    // let conSetTimeout = null;
    // const setTime = 1000;//延迟1.5秒
    //鼠标移入事件
    // const eleMouseenter = function () {
    //   conSetTimeout = setTimeout(function () {
    //     //移入增加DOM节点 （自定义title）
    //     //eleDom表示，需要在哪个DOM下增加title
    //     // eleDom.appendChild(itemDom);
    //     // itemDomShow = true;
    //     // eleDom.parentNode.style= "overflow: visible;";
    //     p.textContent = textTips; //参数textTips 代表需要展示的自定义title文本
    //     p.style = "margin-left:10px;margin-right:10px;"
    //     itemDom.appendChild(p);
    //     img.src = img_pre;
    //     itemDomShow = true;
    //     // itemDom.style = "border: 1px solid #007BFF;left:" + (eleDom.getBoundingClientRect().width + 10) + "px;top:" + (eleDom.getBoundingClientRect().height - 40) + "px;";
    //     itemDom.style = "border: 1px solid #ffffff;left:104%;top:80%;";
    //     div.style = "background: #007BFF;width:30px;height:30px;display: flex;flex-direction: row;align-items: center;justify-content: center;border-bottom-left-radius: 6px;border-top-left-radius: 6px;border: 1px solid rgb(0, 123, 255);";
    //   }, setTime);
    // };
    //鼠标移出
    // const eleMouseleave = function () {
    //   //清除延时器
    //   clearTimeout(Number(conSetTimeout));
    //   if (itemDomShow && !itemClickDomShow) {
    //     //删除节点
    //     // eleDom.removeChild(itemDom);
    //     // itemDomShow = false;
    //     // eleDom.parentNode.style= "overflow: hidden;";
    //     itemDom.removeChild(p);
    //     itemDomShow = false;
    //     img.src = img_nor;
    //     // itemDom.style = "border: 1px solid #ffffff;left:" + (eleDom.getBoundingClientRect().width + 10) + "px;top:" + (eleDom.getBoundingClientRect().height - 40) + "px;";
    //     itemDom.style = "border: 1px solid #ffffff;left:104%;top:80%;";
    //     div.style = "background: #FFFFFF;width:30px;height:30px;display: flex;flex-direction: row;align-items: center;justify-content: center;";
    //   }
    // };
    // 点击后也设置为不显示自定义title
    // const eleClick = function (e) {
    //   if (itemClickDomShow) {
    //     itemDom.removeChild(p);
    //     itemClickDomShow = false;
    //     img.src = img_nor;
    //     // itemDom.style = "border: 1px solid #ffffff;left:" + (eleDom.getBoundingClientRect().width + 10) + "px;top:" + (eleDom.getBoundingClientRect().height - 40) + "px;";
    //     itemDom.style = "border: 1px solid #ffffff;left:104%;top:80%;";
    //     div.style = "background: #FFFFFF;width:30px;height:30px;display: flex;flex-direction: row;align-items: center;justify-content: center;";
    //     lf.graphModel.getNodeModelById(lf.model.id).setProperties({
    //       isShowDescribe: false
    //     });
    //     let nodes = []
    //     nodes.push(lf.graphModel.getNodeModelById(lf.model.id).getData())
    //     sendUpdate(nodes,[],"update", 0)
    //   }
    //   else {
    //     p.textContent = textTips; //参数textTips 代表需要展示的自定义title文本
    //     p.style = "margin-left:10px;margin-right:10px;"
    //     itemDom.appendChild(p);
    //     img.src = img_pre;
    //     itemClickDomShow = true;
    //     // itemDom.style = "border: 1px solid #007BFF;left:" + (eleDom.getBoundingClientRect().width + 10) + "px;top:" + (eleDom.getBoundingClientRect().height - 40) + "px;";
    //     itemDom.style = "border: 1px solid #ffffff;left:104%;top:80%;";
    //     div.style = "background: #007BFF;width:30px;height:30px;display: flex;flex-direction: row;align-items: center;justify-content: center;border-bottom-left-radius: 6px;border-top-left-radius: 6px;border: 1px solid rgb(0, 123, 255);";
    //     lf.graphModel.getNodeModelById(lf.model.id).setProperties({
    //       isShowDescribe: true
    //     });
    //     let nodes = []
    //     nodes.push(lf.graphModel.getNodeModelById(lf.model.id).getData())
    //     sendUpdate(nodes,[],"update", 0)
    //   }
    //   e.stopPropagation();
    // };

    // div.addEventListener("mouseenter", eleMouseenter);
    // div.addEventListener("mouseleave", eleMouseleave);
    // div.addEventListener("click", eleClick);

    div.title = textTips;

    if (itemClickDomShow) {
      p.textContent = textTips; //参数textTips 代表需要展示的自定义title文本
      p.style = "margin-left:10px;margin-right:10px;";
      itemDom.appendChild(p);
      img.src = img_pre;
      // itemDom.style = "border: 1px solid #007BFF;left:" + (eleDom.getBoundingClientRect().width + 10) + "px;top:" + (eleDom.getBoundingClientRect().height - 40) + "px;";
      itemDom.style = "border: 1px solid #ffffff;left:104%;top:80%;";
      div.style =
        "background: #007BFF;width:30px;height:30px;display: flex;flex-direction: row;align-items: center;justify-content: center;border-bottom-left-radius: 6px;border-top-left-radius: 6px;border: 1px solid rgb(0, 123, 255);";
    }

    eleDom.appendChild(itemDom);
  }
  setHtml(rootEl) {
    const { id, properties } = this.props.model;
    // console.log(properties)

    // var r = Math.floor(Math.random() * 256);
    // var g = Math.floor(Math.random() * 256);
    // var b = Math.floor(Math.random() * 256);
    // var color = "rgb(" + r + "," + g + "," + b + ")";
    var color =
      properties.titleColor && properties.titleColor != ""
        ? properties.titleColor
        : "#6292BC";
    let cardTitle =
      window.location.href.indexOf("cocorobo.cn") != -1
        ? zhHansLanguage.thinkingCard
        : window.location.href.indexOf("cocorobo.hk") != -1
        ? zhHantLanguage.thinkingCard
        : enLanguage.thinkingCard;
    const el = document.createElement("div");
    el.className = "uml-wrapper";
    // el.title = properties.describe ? properties.describe : ''
    // el.style = "border: 1px solid " + color + ';';
    var html = ``;
    // if (properties.nodeSize && properties.nodeSize.height && parseInt(properties.nodeSize.height) <= 60 || properties.nodeSize && properties.nodeSize.width && parseInt(properties.nodeSize.width) <= 60) {
    //   el.style = "width:60px;height:60px;";
    //   if (properties.iconList && properties.iconList.length != 0) {
    //     html = `<div style="width:100%;height:100%;display: flex;justify-content: center;align-items: center;">
    //       <img src="${properties.iconList[0].url}" style="max-width:100%;max-height:100%;" draggable="false"/>
    //     </div>
    //     `
    //   }
    //   el.innerHTML = html;
    //   // 需要先把之前渲染的子节点清除掉。
    //   rootEl.innerHTML = '';
    //   rootEl.appendChild(el);
    // }
    // else {
    html = `
      <div class="is_show_user" style="${
        properties.is_show_user ? "display:flex;" : "display:none;"
      }">
        <i class="el-icon" style="font-size: 18px;color:#58aba2;width: 18px;height: 27px;" >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="M628.736 528.896A416 416 0 0 1 928 928H96a415.872 415.872 0 0 1 299.264-399.104L512 704zM720 304a208 208 0 1 1-416 0 208 208 0 0 1 416 0"></path></svg>
        </i>
        <div class="is_show_user_name">${properties.user_name}</div>
        <i class="el-icon" style="font-size: 16px;width: 16px;height: 27px;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="m199.04 672.64 193.984 112 224-387.968-193.92-112-224 388.032zm-23.872 60.16 32.896 148.288 144.896-45.696zM455.04 229.248l193.92 112 56.704-98.112-193.984-112-56.64 98.112zM104.32 708.8l384-665.024 304.768 175.936L409.152 884.8h.064l-248.448 78.336zm384 254.272v-64h448v64h-448z"></path></svg></i>
        <div class="apostrophe"></div>
      </div>
      <div style="width: 100%;height: 100%;">
        <div class="uml-head" style="background-color:${color};">${
      properties.thinkingTitle ? properties.thinkingTitle : cardTitle
    }</div>
        <i id="${id}" class="el-icon closeImg" style="${
          properties.isShowDelete ? "display:block;font-size: 20px;" : "display:none;font-size: 20px;"
        }"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="m466.752 512-90.496-90.496a32 32 0 0 1 45.248-45.248L512 466.752l90.496-90.496a32 32 0 1 1 45.248 45.248L557.248 512l90.496 90.496a32 32 0 1 1-45.248 45.248L512 557.248l-90.496 90.496a32 32 0 0 1-45.248-45.248z"></path><path fill="currentColor" d="M512 896a384 384 0 1 0 0-768 384 384 0 0 0 0 768m0 64a448 448 0 1 1 0-896 448 448 0 0 1 0 896"></path></svg>
        </i>
        <div class="uml-body" style="width: 100%;height:${
          !properties.is_show_user
            ? properties.nodeSize && properties.nodeSize.height
              ? properties.nodeSize.height - 32 + "px"
              : 218 + "px"
            : properties.nodeSize && properties.nodeSize.height
            ? properties.nodeSize.height - 60 + "px"
            : 190 + "px"
        };padding:0;border-top:none;border-bottom-left-radius: 8px;border-bottom-right-radius: 8px;">
          <div style="width: 100%;height:100%;display:flex;align-items:center;justify-content: center;border-bottom-left-radius: 8px;border-bottom-right-radius: 8px;">
            <img crossorigin="anonymous" draggable="false" src="https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/e8215fd4-4b4c-4373-ac36-5ea301aefa73.png" style="max-width:100%;max-height:100%;"/>
          </div>
        </div>
      </div>
      `;
    // if (properties.radio == 'content' && properties.content && properties.content != '') {
    //   var fontsize = $('.uml-body').css('font-size');
    //   // console.log(properties.content.length)
    //   if (0 < properties.content.length && properties.content.length == 1) {
    //     fontsize = "160px";
    //   }
    //   else if (1 < properties.content.length && properties.content.length <= 5) {
    //     fontsize = "40px";
    //   }
    //   else if (5 < properties.content.length) {
    //     fontsize = "10px";
    //   }
    //   // else if(30 < properties.content.length && properties.content.length <= 40) {
    //   //   fontsize = "14px";
    //   // }
    //   // else {
    //   //   fontsize = "12px";
    //   // }
    //   html = `
    //   <div style="height:100%;">
    //     <div class="uml-head" style="background-color:${color};">${properties.thinkingTitle ? properties.thinkingTitle : '卡片'}</div>
    //     <div class="uml-body" style="font-size:${fontsize};height:${properties.nodeSize.height - 11}px;display: flex;justify-content: center;align-items: center;">
    //       ${properties.content}
    //     </div>
    //   </div>
    //   `;
    // }
    // if (properties.radio == 'iframeUrl' && properties.iframeUrl && properties.iframeUrl != '') {
    //   html = `
    //   <div style="height:100%;">
    //     <div class="uml-head" style="background-color:${color};">${properties.thinkingTitle ? properties.thinkingTitle : '卡片'}</div>
    //     <div class="uml-body" style="height:${properties.nodeSize.height - 11}px;">
    //       <div style="width: ${properties.width - 22}px;height:${properties.nodeSize.height - 11}px;"><iframe src=${'//' + properties.iframeUrl} style="position: absolute;transform: scale(.5, .5) translate(-47%, -50%);width: 187%;height: ${(properties.nodeSize.height - 11) * 2}px;top: 34px;left: 0;" frameborder="no" border="0"></iframe></div>
    //     </div>
    //   </div>
    //   `;
    // }
    if (properties.thinkingContent && properties.thinkingContent != "") {
      // console.log(properties.imageList)
      html = `
        <div class="is_show_user" style="${
          properties.is_show_user ? "display:flex;" : "display:none;"
        }">
          <i class="el-icon" style="font-size: 18px;color:#58aba2;width: 18px;height: 27px;" >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="M628.736 528.896A416 416 0 0 1 928 928H96a415.872 415.872 0 0 1 299.264-399.104L512 704zM720 304a208 208 0 1 1-416 0 208 208 0 0 1 416 0"></path></svg>
          </i>
          <div class="is_show_user_name">${properties.user_name}</div>
          <i class="el-icon" style="font-size: 16px;width: 16px;height: 27px;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="m199.04 672.64 193.984 112 224-387.968-193.92-112-224 388.032zm-23.872 60.16 32.896 148.288 144.896-45.696zM455.04 229.248l193.92 112 56.704-98.112-193.984-112-56.64 98.112zM104.32 708.8l384-665.024 304.768 175.936L409.152 884.8h.064l-248.448 78.336zm384 254.272v-64h448v64h-448z"></path></svg></i>
          <div class="apostrophe"></div>
        </div>
        <div style="width: 100%;height: 100%;">
          <div class="uml-head" style="background-color:${color};">${
        properties.thinkingTitle ? properties.thinkingTitle : cardTitle
      }</div>
          <i id="${id}" class="el-icon closeImg" style="${
            properties.isShowDelete ? "display:block;font-size: 20px;" : "display:none;font-size: 20px;"
          }"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="m466.752 512-90.496-90.496a32 32 0 0 1 45.248-45.248L512 466.752l90.496-90.496a32 32 0 1 1 45.248 45.248L557.248 512l90.496 90.496a32 32 0 1 1-45.248 45.248L512 557.248l-90.496 90.496a32 32 0 0 1-45.248-45.248z"></path><path fill="currentColor" d="M512 896a384 384 0 1 0 0-768 384 384 0 0 0 0 768m0 64a448 448 0 1 1 0-896 448 448 0 0 1 0 896"></path></svg>
          </i>
          <div class="uml-body" style="height:${
            !properties.is_show_user
              ? properties.nodeSize && properties.nodeSize.height
                ? properties.nodeSize.height - 32 + "px"
                : 218 + "px"
              : properties.nodeSize && properties.nodeSize.height
              ? properties.nodeSize.height - 60 + "px"
              : 190 + "px"
          };padding: 0;display: flex;flex-direction: row;justify-content: center;align-items: center;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
            <div style="width: 100%;height:100%;display:flex;align-items:center;justify-content: center;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
              <img crossorigin="anonymous" src="${
                properties.thinkingContent
              }" style="max-width:100%;max-height:100%;" draggable="false"/>
            </div>
          </div>
        </div>
        `;
    }
    // if (properties.radio == 'videoList' && properties.videoList && properties.videoList.length != 0) {
    //   // console.log(properties.videoList)
    //   html = `
    //   <div style="width: 100%;height: 100%;">
    //     <div class="uml-head" style="background-color:${color};">${properties.title ? properties.title : '卡片'}</div>
    //     <div class="uml-body" style="width: 100%;height: ${properties.nodeSize.height}px;padding:0;">
    //       <div style="width: 100%;height: ${properties.nodeSize.height}px;display: flex;align-items: center;justify-content: center;"><video style="width: auto;height: auto;max-width: 100%;max-height: 100%;" controls src="${properties.videoList[0].url}"></video></div>
    //     </div>
    //   </div>
    //   `;
    // }
    // if (properties.radio == 'audioList' && properties.audioList && properties.audioList.length != 0) {
    //   // console.log(properties.audioList)
    //   html = `
    //   <div style="width: 100%;height: 100%;">
    //     <div class="uml-head" style="background-color:${color};">${properties.title ? properties.title : '卡片'}</div>
    //     <div class="uml-body" style="width: 100%;height: ${properties.nodeSize.height}px;padding:0;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
    //       <div style="display:flex;width: 100%;height: ${properties.nodeSize.height}px;justify-content: center;align-items: center;"><audio src="${properties.audioList[0].url}" controls /></div>
    //     </div>
    //   </div>
    //   `;
    // }
    el.innerHTML = html;
    // 需要先把之前渲染的子节点清除掉。
    rootEl.innerHTML = "";
    rootEl.appendChild(el);

    let that = this;
    if (document.getElementById(id)) {
      document.getElementById(id).addEventListener("click", (event) => {
        let tip18 =
          window.location.href.indexOf("cocorobo.cn") != -1
            ? zhHansLanguage.tip18
            : window.location.href.indexOf("cocorobo.hk") != -1
            ? zhHantLanguage.tip18
            : enLanguage.tip18;
        let tip2 =
          window.location.href.indexOf("cocorobo.cn") != -1
            ? zhHansLanguage.tip2
            : window.location.href.indexOf("cocorobo.hk") != -1
            ? zhHantLanguage.tip2
            : enLanguage.tip2;
        let confirm =
          window.location.href.indexOf("cocorobo.cn") != -1
            ? zhHansLanguage.confirm
            : window.location.href.indexOf("cocorobo.hk") != -1
            ? zhHantLanguage.confirm
            : enLanguage.confirm;
        let cancel =
          window.location.href.indexOf("cocorobo.cn") != -1
            ? zhHansLanguage.cancel
            : window.location.href.indexOf("cocorobo.hk") != -1
            ? zhHantLanguage.cancel
            : enLanguage.cancel;
        let nodes = [];
        nodes.push(that.props.graphModel.getElement(id).getData());

        MessageBox.confirm(tip18, tip2, {
          confirmButtonText: confirm,
          cancelButtonText: cancel,
          type: "warning",
        })
          .then(() => {
            let newEdges = [];
            let outgoingEdgeModel =
              that.props.graphModel.getNodeOutgoingEdge(id);
            if (outgoingEdgeModel.length != 0) {
              for (let j = 0; j < outgoingEdgeModel.length; j++) {
                let edge = that.props.graphModel.getEdgeModelById(
                  outgoingEdgeModel[j].id
                );
                newEdges.push(edge.getData());
              }
            }
            let incomingEdgeModel =
              that.props.graphModel.getNodeIncomingEdge(id);
            if (incomingEdgeModel.length != 0) {
              for (let j = 0; j < incomingEdgeModel.length; j++) {
                let edge = that.props.graphModel.getEdgeModelById(
                  incomingEdgeModel[j].id
                );
                newEdges.push(edge.getData());
              }
            }
            sendUpdate(nodes, newEdges, "delete", 0);
            that.props.graphModel.deleteNode(id);

            that.props.graphModel.clearSelectElements();
            let newData = {
              nodes: nodes,
              edges: newEdges,
              backgroundUrl: localStorage.getItem("background"),
            };
            add_Operation(newData, "deleteNode");
          })
          .catch(() => {
            // this.$message({
            //   type: 'info',
            //   message: '已取消删除'
            // });
          });
        event.stopPropagation();
      });
    }

    if (properties.describe) {
      const textTips = properties.describe ? properties.describe : "";
      //添加自定义属性 target-title
      //itemContainer表示，需要在哪个DOM下增加title（自定义属性）
      el.setAttribute("target-title", textTips);
      //判断这个DOM节点是否包含这个自定义属性，
      if (el.getAttributeNode("target-title")) {
        //有的话直接调用函数。（用于多个相同DOM命名，统一添加，）
        const textTips = el.getAttribute("target-title");
        //如果用的地方比较少，不需要多个添加，可以直接调用函数
        //itemContainer 表示，需要在哪个DOM下增加title
        //textTips  需要展示的title文本
        this.contentTitle(el, textTips, this.props, properties.isShowDescribe);
      }
    }
    // }
  }
}

export default {
  type: "icon-thinking",
  view: ThinkingCardNode,
  model: ThinkingCardModel,
};
