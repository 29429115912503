<template>
  <div id="wb">
    <el-dialog
      v-model="dialogVisible2"
      width="50%"
      :show-close="false"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :before-close="handleClose"
    >
      <template #header>
        <div style="width: 100%; position: relative">
          <el-button type="primary" size="small" round @click="handleSave">
            {{ languageData.store }}
          </el-button>
          <button type="button" class="close" style="top: 4px; right: 0">
            <img
              v-if="hoverClose"
              src="../../assets/close_pre.png"
              width="16"
              height="16"
              @mouseout="blurClose"
              @click="handleClose"
            />
            <img
              v-else
              src="../../assets/close_nor.png"
              width="16"
              height="16"
              @mouseover="focusClose"
            />
          </button>
        </div>
      </template>
      <iframe
        id="wbIframe"
        :src="iframe_url"
        style="
          width: 100%;
          height: 75vh;
          border: none;
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
        "
      />
    </el-dialog>
  </div>
</template>

<script>
var RandExp = require("randexp");
import { sendUpdate, add_Operation } from "../../tools/send.js";
import { v4 as uuidv4 } from "uuid";
import LogicFlow from "@logicflow/core";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    list: { type: Array, default: undefined },
    lf: { type: LogicFlow, default: undefined },
  },
  emits: ["info"],
  data() {
    return {
      languageData: {},
      iframe_url: "",
      roomid: "",
      hoverClose: false,
      dialogVisible2: false,
    };
  },
  watch: {
    dialogVisible(newVal) {
      if (newVal) {
        this.dialogVisible2 = newVal;
      }
    },
    list(newVal) {
      if (newVal.length != 0) {
        //console.log(newVal)
        // if (newVal[0].properties.whiteboardContent && newVal[0].properties.whiteboardContent != '') {
        //   //加载
        //   //document.getElementById("wbIframe").contentWindow.h.app.updateScene({ elements: newVal[0].properties.whiteboardContent })
        // }
        // if(!newVal[0].properties?.whiteboardContent && document.getElementById("wbIframe")) {
        //   //清空
        //   document.getElementById("wbIframe").contentWindow.h.app.updateScene({ elements: [] })
        // }
        this.iframe_url = "";
        if (newVal[0].properties?.roomid && newVal[0].properties.roomid != "") {
          this.roomid = newVal[0].properties.roomid;
        } else {
          this.roomid = new RandExp(/[0-9a-f]{20},[a-zA-Z0-9_-]{22}/).gen();
          newVal[0].properties.roomid = this.roomid;
          this.$props.lf.getNodeModelById(newVal[0].id).setProperties({
            roomid: this.roomid,
          });
          sendUpdate(newVal, [], "update", 0);
          let newData = {
            nodes: newVal,
            edges: [],
            backgroundUrl: localStorage.getItem("background"),
          };
          add_Operation(newData, "updateNode");
        }
        if (window.location.href.indexOf("cocorobo.cn") != -1) {
          setTimeout(() => {
            this.iframe_url = "//beta.iwb.cocorobo.cn/#room=" + this.roomid;
          }, 500);
        } else if (window.location.href.indexOf("cocorobo.com") != -1) {
          setTimeout(() => {
            this.iframe_url = "//iwb.cocorobo.com/#room=" + this.roomid;
          }, 500);
        } else {
          // setTimeout(()=>{
          //   this.iframe_url="//iwb.cocorobo.hk/#room="+this.roomid;
          // },500)
          setTimeout(() => {
            this.iframe_url = "//iwb.cocorobo.hk/#room=" + this.roomid;
          }, 500);
        }
      }
    },
  },
  mounted() {
    //console.log(this.list)
    if (window.location.href.indexOf("cocorobo.cn") != -1) {
      document.domain = "cocorobo.cn";
      this.languageData = this.zhHansLanguage;
    } else if (window.location.href.indexOf("cocorobo.hk") != -1) {
      // this.iframe_url="//iwb.cocorobo.hk/"
      document.domain = "cocorobo.hk";
      this.languageData = this.zhHantLanguage;
    } else if (window.location.href.indexOf("cocorobo.com") != -1) {
      document.domain = "cocorobo.com";
      this.languageData = this.enLanguage;
    } else {
      this.languageData = this.zhHansLanguage;
    }
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "./aws-sdk-2.235.1.min.js";
    script.async = "async";
    script.onload = function () {
      var credentials = {
        accessKeyId: "AKIATLPEDU37QV5CHLMH",
        secretAccessKey: "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",
      }; //秘钥形式的登录上传
      window.AWS.config.update(credentials);
      window.AWS.config.region = "cn-northwest-1"; //设置区域
    };
    document.body.appendChild(script);
  },
  methods: {
    focusClose() {
      this.hoverClose = true;
    },
    blurClose() {
      this.hoverClose = false;
    },
    handleClose() {
      this.dialogVisible2 = false;
      this.$emit("info", false);
    },
    handleSave() {
      this.downloadPng();
      this.dialogVisible2 = false;
      this.$emit("info", false);
    },
    downloadPng() {
      if (window.location.href.indexOf("cocorobo.cn") != -1) {
        document.domain = "cocorobo.cn";
      } else if (window.location.href.indexOf("cocorobo.hk") != -1) {
        document.domain = "cocorobo.hk";
      } else if (window.location.href.indexOf("cocorobo.com") != -1) {
        document.domain = "cocorobo.com";
      }
      let img = document
        .getElementById("wbIframe")
        .contentWindow.h.app.rc.canvas.toDataURL("image/png");
      let blob = this.dataURLtoBlob(img);
      // 拿到文件名
      let fileName = uuidv4() + ".png";
      // 2，在转为 file类型
      let file1 = this.blobToFile(blob, fileName);
      this.uploadImage(file1);
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    //2,再将blob转换为file
    blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date(); // 文件最后的修改日期
      theBlob.name = fileName; // 文件名
      return new File([theBlob], fileName, {
        type: theBlob.type,
        lastModified: Date.now(),
      });
    },
    uploadImage(fileParams) {
      // console.log(fileParams.file)
      var bucket = new window.AWS.S3({ params: { Bucket: "ccrb" } }); //选择桶
      var _this = this;
      let list = _this.list;
      if (fileParams) {
        var params = {
          Key: fileParams.name,
          ContentType: fileParams.type,
          Body: fileParams,
          "Access-Control-Allow-Credentials": "*",
          ACL: "public-read",
        }; //key可以设置为桶的相抵路径，Body为文件， ACL最好要设置
        var options = {
          partSize: 2048 * 1024 * 1024,
          queueSize: 2,
          leavePartsOnError: true,
        };
        bucket.upload(params, options).send(function (err, data) {
          if (err) {
            console.log(err);
          } else {
            list[0].properties.whiteboardContent = data.Location;
            list[0].properties.roomid = _this.roomid;
            _this.$props.lf.getNodeModelById(list[0].id).setProperties({
              whiteboardContent: list[0].properties.whiteboardContent,
              roomid: _this.roomid,
            });
            sendUpdate(list, [], "update", 0);
            let newData = {
              nodes: list,
              edges: [],
              backgroundUrl: localStorage.getItem("background"),
            };
            add_Operation(newData, "updateNode");
          }
        });
      }
    },
  },
};
</script>

<style>
#wb .el-dialog {
  margin-top: 0 !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: none;
  box-shadow: none;
}

#wb .el-dialog__header {
  width: calc(100% - 48px);
  border-bottom: 1px solid var(--bg3, #e7e7e7);
  background: var(--font-icon-white, #fff);
  border-left: 1px solid var(--bg3, #e7e7e7);
  border-right: 1px solid var(--bg3, #e7e7e7);
  border-top: 1px solid var(--bg3, #e7e7e7);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.0784313725490196);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.0392156862745098);
  box-shadow: 0 6px 30px 5px rgba(0, 0, 0, 0.050980392156862744);
  padding: 8px 24px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
}

#wb .el-dialog__body {
  border-left: 1px solid var(--bg3, #e7e7e7);
  border-right: 1px solid var(--bg3, #e7e7e7);
  border-bottom: 1px solid var(--bg3, #e7e7e7);
  padding: 0;
  /* background: var(--font-icon-white,#fff); */
  padding: 8px 24px;
  width: calc(100% - 48px);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.0784313725490196);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.0392156862745098);
  box-shadow: 0 6px 30px 5px rgba(0, 0, 0, 0.050980392156862744);
}

#wb .close {
  position: absolute;
  top: 0;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}
</style>
