<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    v-if="list.length != 0 && dialogVisible"
    id="showResult"
    v-dialogDrag
    class="showResult"
  >
    <el-dialog
      :model-value="dialogVisible"
      :destroy-on-close="true"
      :fullscreen="fullscreen"
      :before-close="handleClose"
      :show-close="showClose"
    >
      <span style="height: 100%">
        <div style="height: 100%; position: relative">
          <template v-for="(item, index) in list" :key="index">
            <div
              v-if="item.type == 'icon-theme' && show"
              id="icon-theme"
              class="right_top animate__animated animate__zoomIn"
              style="
                margin-left: 0;
                margin-right: 0;
                height: 100%;
                position: relative;
                margin-bottom: 0;
                padding: 0;
                border-radius: 0;
              "
            >
              <div style="height: 100%; position: relative">
                <div
                  class="left-top-1"
                  style="background: var(--brand-normal, #3681fc)"
                />
                <div
                  class="left-top-2"
                  style="
                    background: var(--brand-normal, #3681fc);
                    position: absolute;
                    top: 0;
                  "
                />
                <div
                  id="content"
                  class="themeContent"
                  style="position: absolute; display: flex"
                >
                  <div
                    style="
                      width: 100%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      flex-direction: column;
                    "
                  >
                    <div
                      class="themeDiv"
                      style="
                        background: var(--brand-normal, #3681fc);
                        text-align: center;
                      "
                    >
                      <span class="text">{{ languageData.topic }}</span>
                    </div>
                    <div class="themeText">{{ item.properties.topic }}</div>
                  </div>
                </div>
                <div
                  class="left-top-3"
                  style="
                    background: var(--brand-normal, #3681fc);
                    position: absolute;
                    right: 0;
                  "
                />
                <div
                  class="left-top-2"
                  style="
                    background: var(--brand-normal, #3681fc);
                    position: absolute;
                    bottom: 0;
                    right: 0;
                  "
                />
              </div>
            </div>

            <div
              v-if="item.type == 'icon-stage' && show"
              id="icon-stage"
              class="right_top animate__animated animate__zoomIn"
              style="
                margin-left: 0;
                margin-right: 0;
                height: 100%;
                position: relative;
                margin-bottom: 0;
                padding: 0;
                border-radius: 0;
              "
            >
              <div style="height: 100%; position: relative">
                <div
                  class="left-top-1"
                  :style="
                    item.properties.stageTitleColor
                      ? 'background:' + item.properties.stageTitleColor + ';'
                      : 'background: #1D51B6;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.stageTitleColor
                      ? 'position: absolute;top: 0;background:' +
                        item.properties.stageTitleColor +
                        ';'
                      : 'background: #1D51B6;position: absolute;top: 0;'
                  "
                />
                <div
                  v-if="
                    item.properties.stageContent &&
                    item.properties.stageContent != '<p></p>'
                  "
                  id="content"
                  class="stageContent_1"
                  style="position: absolute; display: flex"
                >
                  <div
                    style="
                      width: 100%;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <div
                      class="themeDiv"
                      :style="
                        item.properties.stageTitleColor
                          ? 'text-align: center;background:' +
                            item.properties.stageTitleColor +
                            ';'
                          : 'background: #1D51B6;text-align: center;'
                      "
                    >
                      <span class="text">{{ languageData.stageName }}</span>
                    </div>
                    <div
                      v-if="
                        item.properties.stageName &&
                        item.properties.stageName != ''
                      "
                      class="stageName"
                    >
                      {{ item.properties.stageName }}
                    </div>
                    <div
                      v-if="!isPhone"
                      :style="
                        item.properties.stageName &&
                        item.properties.stageName != ''
                          ? 'width:100%;height:calc(100% - 112px);display:flex;justify-content: center;'
                          : 'margin-top:24px;width:100%;height:calc(100% - 64px);display:flex;justify-content: center;'
                      "
                    >
                      <span
                        id="content1"
                        style="overflow-y: auto; height: 100%; width: 100%"
                        v-html="item.properties.stageContent"
                      />
                    </div>
                    <div
                      v-else
                      :style="
                        item.properties.stageName &&
                        item.properties.stageName != ''
                          ? 'width:100%;height:calc(100% - 98.5px);display:flex;justify-content: center;'
                          : 'margin-top:12.5px;width:100%;height:calc(100% - 48px);display:flex;justify-content: center;'
                      "
                    >
                      <span
                        id="content1"
                        style="overflow-y: auto; height: 100%; width: 100%"
                        v-html="item.properties.stageContent"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  id="content"
                  class="stageContent_2"
                  style="position: absolute; display: flex"
                >
                  <div
                    style="
                      width: 100%;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <div
                      class="themeDiv"
                      :style="
                        item.properties.stageTitleColor
                          ? 'text-align: center;background:' +
                            item.properties.stageTitleColor +
                            ';'
                          : 'background: #1D51B6;text-align: center;'
                      "
                    >
                      <span class="text">{{ languageData.stageName }}</span>
                    </div>
                    <div class="themeText">{{ item.properties.stageName }}</div>
                  </div>
                </div>
                <div
                  class="left-top-3"
                  :style="
                    item.properties.stageTitleColor
                      ? 'position: absolute;right:0;background:' +
                        item.properties.stageTitleColor +
                        ';'
                      : 'background: #1D51B6;position: absolute;right:0;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.stageTitleColor
                      ? 'position: absolute;bottom: 0;right:0;background:' +
                        item.properties.stageTitleColor +
                        ';'
                      : 'background: #1D51B6;position: absolute;bottom: 0;right:0;'
                  "
                />
              </div>
            </div>

            <div
              v-if="item.type == 'icon-task' && show"
              id="icon-task"
              class="right_top animate__animated animate__zoomIn"
              style="
                margin-left: 0;
                margin-right: 0;
                height: 100%;
                position: relative;
                margin-bottom: 0;
                padding: 0;
                border-radius: 0;
              "
            >
              <div style="height: 100%; position: relative">
                <div
                  class="left-top-1"
                  :style="
                    item.properties.taskTitleColor
                      ? 'background:' + item.properties.taskTitleColor + ';'
                      : 'background: #6E44C9;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.taskTitleColor
                      ? 'position: absolute;top: 0;background:' +
                        item.properties.taskTitleColor +
                        ';'
                      : 'background: #6E44C9;position: absolute;top: 0;'
                  "
                />
                <div
                  v-if="
                    item.properties.taskContent &&
                    item.properties.taskContent != '<p></p>'
                  "
                  id="content"
                  class="stageContent_1"
                  style="position: absolute; display: flex"
                >
                  <div style="width: 100%">
                    <div
                      class="themeDiv"
                      :style="
                        item.properties.taskTitleColor
                          ? 'text-align: center;background:' +
                            item.properties.taskTitleColor +
                            ';'
                          : 'background: #6E44C9;text-align: center;'
                      "
                    >
                      <span class="taskText">{{ languageData.taskName }}</span>
                    </div>
                    <div
                      v-if="
                        item.properties.taskTitle &&
                        item.properties.taskTitle != ''
                      "
                      class="taskName"
                    >
                      {{ item.properties.taskTitle }}
                    </div>
                    <div
                      v-if="!isPhone"
                      :style="
                        item.properties.taskTitle &&
                        item.properties.taskTitle != ''
                          ? 'width:100%;height:calc(100% - 112px);display:flex;justify-content: center;'
                          : 'margin-top:24px;width:100%;height:calc(100% - 64px);display:flex;justify-content: center;'
                      "
                    >
                      <span
                        id="content2"
                        style="overflow-y: auto; height: 100%; width: 100%"
                        v-html="item.properties.taskContent"
                      />
                    </div>
                    <div
                      v-else
                      :style="
                        item.properties.taskTitle &&
                        item.properties.taskTitle != ''
                          ? 'width:100%;height:calc(100% - 98.5px);display:flex;justify-content: center;'
                          : 'margin-top:12.5px;width:100%;height:calc(100% - 48px);display:flex;justify-content: center;'
                      "
                    >
                      <span
                        id="content2"
                        style="overflow-y: auto; height: 100%; width: 100%"
                        v-html="item.properties.taskContent"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  id="content"
                  class="stageContent_2"
                  style="position: absolute; display: flex"
                >
                  <div
                    style="
                      width: 100%;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <div
                      class="themeDiv"
                      :style="
                        item.properties.taskTitleColor
                          ? 'text-align: center;background:' +
                            item.properties.taskTitleColor +
                            ';'
                          : 'background: #6E44C9;text-align: center;'
                      "
                    >
                      <span class="text">{{ languageData.taskName }}</span>
                    </div>
                    <div class="themeText">{{ item.properties.taskTitle }}</div>
                  </div>
                </div>
                <div
                  class="left-top-3"
                  :style="
                    item.properties.taskTitleColor
                      ? 'position: absolute;right:0;background:' +
                        item.properties.taskTitleColor +
                        ';'
                      : 'background: #6E44C9;position: absolute;right:0;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.taskTitleColor
                      ? 'position: absolute;bottom: 0;right:0;background:' +
                        item.properties.taskTitleColor +
                        ';'
                      : 'background: #6E44C9;position: absolute;bottom: 0;right:0;'
                  "
                />
              </div>
            </div>

            <div
              v-if="item.type == 'icon-card' && show"
              class="right_top animate__animated animate__zoomIn"
              style="
                margin-left: 0;
                margin-right: 0;
                height: 100%;
                position: relative;
                margin-bottom: 0;
                padding: 0;
                border-radius: 0;
              "
            >
              <div
                v-if="!item.properties.radio"
                style="height: 100%; position: relative"
              >
                <div
                  class="left-top-1"
                  :style="
                    item.properties.titleColor
                      ? 'background:' + item.properties.titleColor + ';'
                      : 'background: #6266BC;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;top: 0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;top: 0;'
                  "
                />

                <div
                  id="content"
                  class="themeContent"
                  style="position: absolute; display: flex"
                >
                  <div
                    style="
                      width: 100%;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <div class="themeText">
                      {{
                        item.properties.title && item.properties.title != ""
                          ? item.properties.title
                          : ""
                      }}
                    </div>
                  </div>
                </div>
                <div
                  class="left-top-3"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;right:0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;right:0;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;bottom: 0;right:0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;bottom: 0;right:0;'
                  "
                />
              </div>
              <div
                v-else-if="item.properties.radio == 'content'"
                style="height: 100%; position: relative"
              >
                <div
                  class="left-top-1"
                  :style="
                    item.properties.titleColor
                      ? 'background:' + item.properties.titleColor + ';'
                      : 'background: #6266BC;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;top: 0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;top: 0;'
                  "
                />

                <div
                  v-if="
                    item.properties.content &&
                    item.properties.content != '<p></p>'
                  "
                  id="content"
                  class="stageContent_1"
                  style="position: absolute; display: flex"
                >
                  <div style="width: 100%">
                    <div class="taskName">
                      {{
                        item.properties.title && item.properties.title != ""
                          ? item.properties.title
                          : ""
                      }}
                    </div>
                    <div
                      v-if="!isPhone"
                      style="
                        width: 100%;
                        height: calc(100% - 48px);
                        display: flex;
                        justify-content: center;
                      "
                    >
                      <span
                        id="content1"
                        style="overflow-y: auto; height: 100%; width: 100%"
                        v-html="item.properties.content"
                      />
                    </div>
                    <div
                      v-else
                      style="
                        width: 100%;
                        height: calc(100% - 50.5px);
                        display: flex;
                        justify-content: center;
                      "
                    >
                      <span
                        id="content1"
                        style="overflow-y: auto; height: 100%; width: 100%"
                        v-html="item.properties.content"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  id="content"
                  class="themeContent"
                  style="position: absolute; display: flex"
                >
                  <div
                    style="
                      width: 100%;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <div class="themeText">
                      {{
                        item.properties.title && item.properties.title != ""
                          ? item.properties.title
                          : ""
                      }}
                    </div>
                  </div>
                </div>
                <div
                  class="left-top-3"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;right:0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;right:0;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;bottom: 0;right:0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;bottom: 0;right:0;'
                  "
                />
              </div>
              <div
                v-else-if="
                  item.properties.radio == 'iframeUrl' &&
                  item.properties.iframeUrl != ''
                "
                style="height: 100%; position: relative"
              >
                <div
                  class="left-top-1"
                  :style="
                    item.properties.titleColor
                      ? 'background:' + item.properties.titleColor + ';'
                      : 'background: #6266BC;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;top: 0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;top: 0;'
                  "
                />

                <div
                  class="cardContent"
                  style="position: absolute; display: flex"
                >
                  <div
                    v-if="
                      !isPhone &&
                      item.properties.title &&
                      item.properties.title != ''
                    "
                    style="width: 200px; margin-right: 30px"
                  >
                    <div class="taskName">
                      {{
                        item.properties.title && item.properties.title != ""
                          ? item.properties.title
                          : ""
                      }}
                    </div>
                  </div>
                  <div
                    v-else-if="
                      isPhone &&
                      item.properties.title &&
                      item.properties.title != ''
                    "
                    style="width: 300px; margin-right: 30px"
                  >
                    <div class="taskName">
                      {{
                        item.properties.title && item.properties.title != ""
                          ? item.properties.title
                          : ""
                      }}
                    </div>
                  </div>
                  <div
                    style="
                      width: 100%;
                      background: var(--brand-light, #e0eafb);
                      justify-content: center;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <iframe
                      style="
                        position: absolute;
                        transform: scale(0.5, 0.5) translate(-47%, -50%);
                        width: 142%;
                        height: 199%;
                        top: 0px;
                        left: 177px;
                      "
                      :src="
                        item.properties.iframeUrl.indexOf('https://') != -1 ||
                        item.properties.iframeUrl.indexOf('http://') != -1
                          ? item.properties.iframeUrl
                          : '//' + item.properties.iframeUrl
                      "
                      frameborder="no"
                      border="0"
                      sandbox="allow-forms allow-scripts allow-same-origin"
                    />
                  </div>
                </div>
                <div
                  class="left-top-3"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;right:0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;right:0;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;bottom: 0;right:0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;bottom: 0;right:0;'
                  "
                />
              </div>
              <div
                v-else-if="item.properties.radio == 'embedCode'"
                style="height: 100%; position: relative"
              >
                <div
                  class="left-top-1"
                  :style="
                    item.properties.titleColor
                      ? 'background:' + item.properties.titleColor + ';'
                      : 'background: #6266BC;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;top: 0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;top: 0;'
                  "
                />
                <div
                  class="cardContent"
                  style="position: absolute; display: flex"
                >
                  <div
                    v-if="
                      !isPhone &&
                      item.properties.title &&
                      item.properties.title != ''
                    "
                    style="width: 200px; margin-right: 30px"
                  >
                    <div class="taskName">
                      {{
                        item.properties.title && item.properties.title != ""
                          ? item.properties.title
                          : ""
                      }}
                    </div>
                  </div>
                  <div
                    v-else-if="
                      isPhone &&
                      item.properties.title &&
                      item.properties.title != ''
                    "
                    style="width: 300px; margin-right: 30px"
                  >
                    <div class="taskName">
                      {{
                        item.properties.title && item.properties.title != ""
                          ? item.properties.title
                          : ""
                      }}
                    </div>
                  </div>
                  <div
                    class="embedCode"
                    style="
                      width: 100%;
                      background: var(--brand-light, #e0eafb);
                      justify-content: center;
                      display: flex;
                      align-items: center;
                    "
                    v-html="item.properties.embedCode"
                  />
                </div>
                <div
                  class="left-top-3"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;right:0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;right:0;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;bottom: 0;right:0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;bottom: 0;right:0;'
                  "
                />
              </div>
              <div
                v-else-if="
                  item.properties.radio == 'imageList' &&
                  item.properties.imageList &&
                  item.properties.imageList.length != 0 &&
                  item.properties.imageList[0].url
                "
                style="height: 100%; position: relative"
              >
                <div
                  class="left-top-1"
                  :style="
                    item.properties.titleColor
                      ? 'background:' + item.properties.titleColor + ';'
                      : 'background: #6266BC;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;top: 0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;top: 0;'
                  "
                />
                <div
                  class="cardContent"
                  style="position: absolute; display: flex"
                >
                  <div
                    v-if="
                      !isPhone &&
                      item.properties.title &&
                      item.properties.title != ''
                    "
                    style="width: 200px; margin-right: 30px"
                  >
                    <div class="taskName">
                      {{
                        item.properties.title && item.properties.title != ""
                          ? item.properties.title
                          : ""
                      }}
                    </div>
                  </div>
                  <div
                    v-else-if="
                      isPhone &&
                      item.properties.title &&
                      item.properties.title != ''
                    "
                    style="width: 300px; margin-right: 30px"
                  >
                    <div class="taskName">
                      {{
                        item.properties.title && item.properties.title != ""
                          ? item.properties.title
                          : ""
                      }}
                    </div>
                  </div>
                  <div
                    style="
                      width: 100%;
                      background: var(--brand-light, #e0eafb);
                      justify-content: center;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <img
                      style="
                        width: auto;
                        height: auto;
                        max-width: 100%;
                        max-height: 100%;
                      "
                      :src="item.properties.imageList[0].url"
                    />
                  </div>
                </div>
                <div
                  class="left-top-3"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;right:0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;right:0;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;bottom: 0;right:0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;bottom: 0;right:0;'
                  "
                />
              </div>
              <div
                v-else-if="
                  item.properties.radio == 'videoList' &&
                  item.properties.videoList &&
                  item.properties.videoList.length != '' &&
                  item.properties.videoList[0].url
                "
                style="height: 100%; position: relative"
              >
                <div
                  class="left-top-1"
                  :style="
                    item.properties.titleColor
                      ? 'background:' + item.properties.titleColor + ';'
                      : 'background: #6266BC;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;top: 0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;top: 0;'
                  "
                />
                <div
                  class="cardContent"
                  style="position: absolute; display: flex"
                >
                  <div
                    v-if="
                      !isPhone &&
                      item.properties.title &&
                      item.properties.title != ''
                    "
                    style="width: 200px; margin-right: 30px"
                  >
                    <div class="taskName">
                      {{
                        item.properties.title && item.properties.title != ""
                          ? item.properties.title
                          : ""
                      }}
                    </div>
                  </div>
                  <div
                    v-else-if="
                      isPhone &&
                      item.properties.title &&
                      item.properties.title != ''
                    "
                    style="width: 300px; margin-right: 30px"
                  >
                    <div class="taskName">
                      {{
                        item.properties.title && item.properties.title != ""
                          ? item.properties.title
                          : ""
                      }}
                    </div>
                  </div>
                  <div
                    style="
                      width: 100%;
                      background: var(--brand-light, #e0eafb);
                      justify-content: center;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <video
                      controls
                      :src="item.properties.videoList[0].url"
                      style="
                        width: auto;
                        height: auto;
                        max-width: 100%;
                        max-height: 100%;
                      "
                    />
                  </div>
                </div>
                <div
                  class="left-top-3"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;right:0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;right:0;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;bottom: 0;right:0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;bottom: 0;right:0;'
                  "
                />
              </div>
              <div
                v-else-if="
                  item.properties.radio == 'audioList' &&
                  item.properties.audioList &&
                  item.properties.audioList.length != '' &&
                  item.properties.audioList[0].url
                "
                style="height: 100%; position: relative"
              >
                <div
                  class="left-top-1"
                  :style="
                    item.properties.titleColor
                      ? 'background:' + item.properties.titleColor + ';'
                      : 'background: #6266BC;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;top: 0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;top: 0;'
                  "
                />
                <div
                  class="cardContent"
                  style="position: absolute; display: flex"
                >
                  <div
                    style="
                      width: 100%;
                      justify-content: center;
                      display: flex;
                      align-items: center;
                      flex-direction: column;
                    "
                  >
                    <div class="taskName">
                      {{
                        item.properties.title && item.properties.title != ""
                          ? item.properties.title
                          : ""
                      }}
                    </div>
                    <audio
                      v-if="!isPhone"
                      controls
                      :src="item.properties.audioList[0].url"
                      style="width: 450px"
                    />
                    <audio
                      v-else
                      controls
                      :src="item.properties.audioList[0].url"
                      style="width: 300px"
                    />
                  </div>
                </div>
                <div
                  class="left-top-3"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;right:0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;right:0;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;bottom: 0;right:0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;bottom: 0;right:0;'
                  "
                />
              </div>
              <div
                v-else-if="
                  item.properties.radio == 'fileList' &&
                  item.properties.fileList &&
                  item.properties.fileList.length != 0 &&
                  (item.properties.fileList[0].url.indexOf('.pdf') != -1 ||
                    item.properties.fileList[0].url.indexOf('.PDF') != -1)
                "
                style="height: 100%; position: relative"
              >
                <div
                  class="left-top-1"
                  :style="
                    item.properties.titleColor
                      ? 'background:' + item.properties.titleColor + ';'
                      : 'background: #6266BC;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;top: 0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;top: 0;'
                  "
                />
                <div
                  class="cardContent"
                  style="position: absolute; display: flex"
                >
                  <div
                    v-if="
                      !isPhone &&
                      item.properties.title &&
                      item.properties.title != ''
                    "
                    style="width: 200px; margin-right: 30px"
                  >
                    <div class="taskName">
                      {{
                        item.properties.title && item.properties.title != ""
                          ? item.properties.title
                          : ""
                      }}
                    </div>
                  </div>
                  <div
                    v-else-if="
                      isPhone &&
                      item.properties.title &&
                      item.properties.title != ''
                    "
                    style="width: 300px; margin-right: 30px"
                  >
                    <div class="taskName">
                      {{
                        item.properties.title && item.properties.title != ""
                          ? item.properties.title
                          : ""
                      }}
                    </div>
                  </div>
                  <div
                    style="
                      width: 100%;
                      background: var(--brand-light, #e0eafb);
                      justify-content: center;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <iframe
                      style="
                        position: absolute;
                        width: 78%;
                        height: 90%;
                        top: 5%;
                      "
                      :src="
                        'https://cloud.cocorobo.cn/pdf.js/web/viewer.html?file=' +
                        item.properties.fileList[0].url
                      "
                      frameborder="no"
                      border="0"
                    />
                  </div>
                </div>
                <div
                  class="left-top-3"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;right:0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;right:0;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;bottom: 0;right:0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;bottom: 0;right:0;'
                  "
                />
              </div>
              <div
                v-else-if="
                  item.properties.radio == 'fileList' &&
                  item.properties.fileList &&
                  item.properties.fileList.length != 0 &&
                  item.properties.fileList[0].url.indexOf('.pdf') == -1 &&
                  item.properties.fileList[0].url.indexOf('.PDF') == -1
                "
                style="height: 100%; position: relative"
              >
                <div
                  class="left-top-1"
                  :style="
                    item.properties.titleColor
                      ? 'background:' + item.properties.titleColor + ';'
                      : 'background: #6266BC;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;top: 0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;top: 0;'
                  "
                />
                <div
                  class="cardContent"
                  style="position: absolute; display: flex"
                >
                  <div
                    v-if="
                      !isPhone &&
                      item.properties.title &&
                      item.properties.title != ''
                    "
                    style="width: 200px; margin-right: 30px"
                  >
                    <div class="taskName">
                      {{
                        item.properties.title && item.properties.title != ""
                          ? item.properties.title
                          : ""
                      }}
                    </div>
                  </div>
                  <div
                    v-else-if="
                      isPhone &&
                      item.properties.title &&
                      item.properties.title != ''
                    "
                    style="width: 300px; margin-right: 30px"
                  >
                    <div class="taskName">
                      {{
                        item.properties.title && item.properties.title != ""
                          ? item.properties.title
                          : ""
                      }}
                    </div>
                  </div>
                  <div
                    style="
                      width: 100%;
                      background: var(--brand-light, #e0eafb);
                      justify-content: center;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <iframe
                      style="
                        position: absolute;
                        width: 78%;
                        height: 90%;
                        top: 5%;
                      "
                      :src="
                        'https://view.officeapps.live.com/op/view.aspx?src=' +
                        item.properties.fileList[0].url
                      "
                      frameborder="no"
                      border="0"
                    />
                  </div>
                </div>
                <div
                  class="left-top-3"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;right:0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;right:0;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;bottom: 0;right:0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;bottom: 0;right:0;'
                  "
                />
              </div>
              <div v-else style="height: 100%; position: relative">
                <div
                  class="left-top-1"
                  :style="
                    item.properties.titleColor
                      ? 'background:' + item.properties.titleColor + ';'
                      : 'background: #6266BC;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;top: 0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;top: 0;'
                  "
                />

                <div
                  id="content"
                  class="themeContent"
                  style="position: absolute; display: flex"
                >
                  <div
                    style="
                      width: 100%;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <div class="themeText">
                      {{
                        item.properties.title && item.properties.title != ""
                          ? item.properties.title
                          : ""
                      }}
                    </div>
                  </div>
                </div>
                <div
                  class="left-top-3"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;right:0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;right:0;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.titleColor
                      ? 'position: absolute;bottom: 0;right:0;background:' +
                        item.properties.titleColor +
                        ';'
                      : 'background: #6266BC;position: absolute;bottom: 0;right:0;'
                  "
                />
              </div>
            </div>

            <!-- <div class="right_top animate__animated animate__zoomIn" v-if="item.type == 'icon-select' && show" 
                            style="margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;padding:0;border-radius: 0;">
                            <div style="height:100%;">
                                <div class="left-top-1" :style="item.properties.selectTitleColor ? 'background:' + item.properties.selectTitleColor +';' : 'background: #F284B2;'"></div>
                                <div class="left-top-2" :style="item.properties.selectTitleColor ? 'position: absolute;top: 0;background:' + item.properties.selectTitleColor +';' : 'background: #F284B2;position: absolute;top: 0;'"></div>
                                <div class="themeContent" style="position: absolute;display:flex;" >
                                    <div style="width:100%;">
                                        <div style="color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));font-family: SimSun;font-size: 64px;font-style: normal;font-weight: 400;line-height: 112px;letter-spacing: 7.68px;">{{ item.properties.selectTitle }}</div>
                                        <div style="color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));font-family: SimSun;font-size: 32px;font-style: normal;font-weight: 400;line-height: 48px;">{{ item.properties.selectContent }}</div>
                                        <div style="margin-top:10px;display:flex;align-items: center;flex-wrap: wrap;">
                                            <template v-for="(option, index) in item.properties.selectOption" :key="'letterItemList' + index">
                                                <el-radio  v-model="Radio" :value="option" :label="option"
                                                    style="width: calc(50%);color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));font-family: SimSun;font-size: 32px;font-style: normal;font-weight: 400;" @change="setSelect">
                                                    {{ letterList[index] }}.
                                                    {{ option }}
                                                </el-radio>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.selectTitleColor ? 'position: absolute;right:0;background:' + item.properties.selectTitleColor +';' : 'background: #F284B2;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.selectTitleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.selectTitleColor +';' : 'background: #F284B2;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                        </div> -->

            <div
              v-if="item.type == 'icon-thinking' && show"
              class="right_top animate__animated animate__zoomIn"
              style="
                margin-left: 0;
                margin-right: 0;
                height: 100%;
                position: relative;
                margin-bottom: 0;
                padding: 0;
                border-radius: 0;
              "
            >
              <div style="height: 100%; position: relative">
                <div class="left-top-1" style="background: #6292bc" />
                <div
                  class="left-top-2"
                  style="background: #6292bc; position: absolute; top: 0"
                />
                <div
                  class="themeContent"
                  style="position: absolute; display: flex"
                >
                  <div style="width: 100%">
                    <div
                      style="
                        width: 100%;
                        height: calc(100%);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <img
                        style="
                          width: auto;
                          height: auto;
                          max-width: calc(100%);
                          max-height: calc(100%);
                        "
                        :src="
                          item.properties.thinkingContent
                            ? item.properties.thinkingContent
                            : 'https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/e8215fd4-4b4c-4373-ac36-5ea301aefa73.png'
                        "
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="left-top-3"
                  style="background: #6292bc; position: absolute; right: 0"
                />
                <div
                  class="left-top-2"
                  style="
                    background: #6292bc;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                  "
                />
              </div>
            </div>

            <div
              v-if="item.type == 'icon-AI' && show"
              class="right_top animate__animated animate__zoomIn"
              style="
                margin-left: 0;
                margin-right: 0;
                height: 100%;
                position: relative;
                margin-bottom: 0;
                padding: 0;
                border-radius: 0;
              "
            >
              <div
                v-if="item.properties.radio == 'description'"
                style="height: 100%; position: relative"
              >
                <div
                  class="left-top-1"
                  :style="
                    item.properties.AITitleColor
                      ? 'background:' + item.properties.AITitleColor + ';'
                      : 'background: #80BFB0;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.AITitleColor
                      ? 'position: absolute;top: 0;background:' +
                        item.properties.AITitleColor +
                        ';'
                      : 'background: #80BFB0;position: absolute;top: 0;'
                  "
                />
                <div
                  class="stageContent_1"
                  style="position: absolute; display: flex"
                >
                  <div style="width: 100%">
                    <div class="taskName">{{ item.properties.topic }}</div>
                    <div
                      v-if="!isPhone"
                      style="
                        width: 100%;
                        height: calc(100% - 48px);
                        display: flex;
                        justify-content: center;
                      "
                    >
                      <span id="content1" style="overflow-y: auto">{{
                        item.properties.aiContent
                      }}</span>
                    </div>
                    <div
                      v-else
                      style="
                        width: 100%;
                        height: calc(100% - 50.5px);
                        display: flex;
                        justify-content: center;
                      "
                    >
                      <span id="content1" style="overflow-y: auto">{{
                        item.properties.aiContent
                      }}</span>
                    </div>
                  </div>
                </div>
                <div
                  class="left-top-3"
                  :style="
                    item.properties.AITitleColor
                      ? 'position: absolute;right:0;background:' +
                        item.properties.AITitleColor +
                        ';'
                      : 'background: #80BFB0;position: absolute;right:0;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.AITitleColor
                      ? 'position: absolute;bottom: 0;right:0;background:' +
                        item.properties.AITitleColor +
                        ';'
                      : 'background: #80BFB0;position: absolute;bottom: 0;right:0;'
                  "
                />
              </div>
              <div
                v-else-if="
                  item.properties.radio == 'image' &&
                  item.properties.imgResult != ''
                "
                style="height: 100%"
              >
                <div
                  class="left-top-1"
                  :style="
                    item.properties.AITitleColor
                      ? 'background:' + item.properties.AITitleColor + ';'
                      : 'background: #80BFB0;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.AITitleColor
                      ? 'position: absolute;top: 0;background:' +
                        item.properties.AITitleColor +
                        ';'
                      : 'background: #80BFB0;position: absolute;top: 0;'
                  "
                />
                <div
                  class="themeContent"
                  style="position: absolute; display: flex"
                >
                  <div style="width: 100%">
                    <div class="taskName">{{ item.properties.topic }}</div>
                    <div
                      v-if="!isPhone"
                      style="
                        width: 100%;
                        height: calc(100% - 48px);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <img
                        style="
                          width: auto;
                          height: auto;
                          max-width: 100%;
                          max-height: 100%;
                        "
                        :src="item.properties.imgResult"
                      />
                    </div>
                    <div
                      v-else
                      style="
                        width: 100%;
                        height: calc(100% - 50.5px);
                        display: flex;
                        justify-content: center;
                      "
                    >
                      <img
                        style="
                          width: auto;
                          height: auto;
                          max-width: 100%;
                          max-height: 100%;
                        "
                        :src="item.properties.imgResult"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="left-top-3"
                  :style="
                    item.properties.AITitleColor
                      ? 'position: absolute;right:0;background:' +
                        item.properties.AITitleColor +
                        ';'
                      : 'background: #80BFB0;position: absolute;right:0;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.AITitleColor
                      ? 'position: absolute;bottom: 0;right:0;background:' +
                        item.properties.AITitleColor +
                        ';'
                      : 'background: #80BFB0;position: absolute;bottom: 0;right:0;'
                  "
                />
              </div>
            </div>

            <div
              v-if="item.type == 'icon-new-AI' && show"
              class="right_top animate__animated animate__zoomIn"
              style="
                margin-left: 0;
                margin-right: 0;
                height: 100%;
                position: relative;
                margin-bottom: 0;
                padding: 0;
                border-radius: 0;
              "
            >
              <div
                v-if="item.properties.radio == 'description'"
                style="height: 100%; position: relative"
              >
                <div
                  class="left-top-1"
                  :style="
                    item.properties.AITitleColor
                      ? 'background:' + item.properties.AITitleColor + ';'
                      : 'background: #80BFB0;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.AITitleColor
                      ? 'position: absolute;top: 0;background:' +
                        item.properties.AITitleColor +
                        ';'
                      : 'background: #80BFB0;position: absolute;top: 0;'
                  "
                />
                <div
                  class="stageContent_1"
                  style="position: absolute; display: flex"
                >
                  <div style="width: 100%">
                    <div
                      class="taskName"
                      style="
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                      "
                      :title="item.properties.topic"
                    >
                      {{ item.properties.topic }}
                    </div>
                    <div
                      v-if="!isPhone"
                      style="
                        width: 100%;
                        height: calc(100% - 48px);
                        display: flex;
                        justify-content: center;
                      "
                    >
                      <span id="content1" style="overflow-y: auto">{{
                        item.properties.aiContent
                      }}</span>
                    </div>
                    <div
                      v-else
                      style="
                        width: 100%;
                        height: calc(100% - 50.5px);
                        display: flex;
                        justify-content: center;
                      "
                    >
                      <span id="content1" style="overflow-y: auto">{{
                        item.properties.aiContent
                      }}</span>
                    </div>
                  </div>
                </div>
                <div
                  class="left-top-3"
                  :style="
                    item.properties.AITitleColor
                      ? 'position: absolute;right:0;background:' +
                        item.properties.AITitleColor +
                        ';'
                      : 'background: #80BFB0;position: absolute;right:0;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.AITitleColor
                      ? 'position: absolute;bottom: 0;right:0;background:' +
                        item.properties.AITitleColor +
                        ';'
                      : 'background: #80BFB0;position: absolute;bottom: 0;right:0;'
                  "
                />
              </div>
              <div
                v-else-if="
                  item.properties.radio == 'image' &&
                  item.properties.imgResult != ''
                "
                style="height: 100%"
              >
                <div
                  class="left-top-1"
                  :style="
                    item.properties.AITitleColor
                      ? 'background:' + item.properties.AITitleColor + ';'
                      : 'background: #80BFB0;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.AITitleColor
                      ? 'position: absolute;top: 0;background:' +
                        item.properties.AITitleColor +
                        ';'
                      : 'background: #80BFB0;position: absolute;top: 0;'
                  "
                />
                <div
                  class="themeContent"
                  style="position: absolute; display: flex"
                >
                  <div style="width: 100%">
                    <div
                      class="taskName"
                      style="
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                      "
                      :title="item.properties.topic"
                    >
                      {{ item.properties.topic }}
                    </div>
                    <div
                      v-if="!isPhone"
                      style="
                        width: 100%;
                        height: calc(100% - 48px);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <img
                        style="
                          width: auto;
                          height: auto;
                          max-width: 100%;
                          max-height: 100%;
                        "
                        :src="item.properties.imgResult"
                      />
                    </div>
                    <div
                      v-else
                      style="
                        width: 100%;
                        height: calc(100% - 50.5px);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <img
                        style="
                          width: auto;
                          height: auto;
                          max-width: 100%;
                          max-height: 100%;
                        "
                        :src="item.properties.imgResult"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="left-top-3"
                  :style="
                    item.properties.AITitleColor
                      ? 'position: absolute;right:0;background:' +
                        item.properties.AITitleColor +
                        ';'
                      : 'background: #80BFB0;position: absolute;right:0;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.AITitleColor
                      ? 'position: absolute;bottom: 0;right:0;background:' +
                        item.properties.AITitleColor +
                        ';'
                      : 'background: #80BFB0;position: absolute;bottom: 0;right:0;'
                  "
                />
              </div>
              <div
                v-else-if="
                  item.properties.radio == 'audio' &&
                  item.properties.audioList.length != 0
                "
                style="height: 100%"
              >
                <div
                  class="left-top-1"
                  :style="
                    item.properties.AITitleColor
                      ? 'background:' + item.properties.AITitleColor + ';'
                      : 'background: #80BFB0;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.AITitleColor
                      ? 'position: absolute;top: 0;background:' +
                        item.properties.AITitleColor +
                        ';'
                      : 'background: #80BFB0;position: absolute;top: 0;'
                  "
                />
                <div
                  class="themeContent"
                  style="position: absolute; display: flex"
                >
                  <div style="width: 100%">
                    <div
                      class="taskName"
                      style="
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                      "
                      :title="item.properties.topic"
                    >
                      {{ item.properties.topic }}
                    </div>
                    <div
                      v-if="!isPhone"
                      style="
                        width: 100%;
                        height: calc(100% - 48px);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <audio
                        style="max-width: 100%; max-height: 100%"
                        :src="item.properties.audioList[0].url"
                        controls
                      />
                    </div>
                    <div
                      v-else
                      style="
                        width: 100%;
                        height: calc(100% - 50.5px);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <audio
                        style="max-width: 100%; max-height: 100%"
                        :src="item.properties.audioList[0].url"
                        controls
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="left-top-3"
                  :style="
                    item.properties.AITitleColor
                      ? 'position: absolute;right:0;background:' +
                        item.properties.AITitleColor +
                        ';'
                      : 'background: #80BFB0;position: absolute;right:0;'
                  "
                />
                <div
                  class="left-top-2"
                  :style="
                    item.properties.AITitleColor
                      ? 'position: absolute;bottom: 0;right:0;background:' +
                        item.properties.AITitleColor +
                        ';'
                      : 'background: #80BFB0;position: absolute;bottom: 0;right:0;'
                  "
                />
              </div>
            </div>

            <div
              v-if="item.type == 'icon-whiteboard' && show"
              class="right_top animate__animated animate__zoomIn"
              style="
                margin-left: 0;
                margin-right: 0;
                height: 100%;
                position: relative;
                margin-bottom: 0;
                padding: 0;
                border-radius: 0;
              "
            >
              <div style="height: 100%; position: relative">
                <div class="left-top-1" style="background: #6292bc" />
                <div
                  class="left-top-2"
                  style="background: #6292bc; position: absolute; top: 0"
                />
                <div
                  class="themeContent"
                  style="position: absolute; display: flex"
                >
                  <div style="width: 100%">
                    <div
                      style="
                        width: 100%;
                        height: calc(100%);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <img
                        v-if="
                          item.properties.whiteboardContent &&
                          item.properties.whiteboardContent != ''
                        "
                        style="
                          width: auto;
                          height: auto;
                          max-width: calc(100%);
                          max-height: calc(100%);
                        "
                        :src="item.properties.whiteboardContent"
                      />
                      <p v-else class="stageName">{{ languageData.tip76 }}</p>
                    </div>
                  </div>
                </div>
                <div
                  class="left-top-3"
                  style="background: #6292bc; position: absolute; right: 0"
                />
                <div
                  class="left-top-2"
                  style="
                    background: #6292bc;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                  "
                />
              </div>
            </div>

            <div
              v-if="item.type == 'icon-map' && show"
              class="right_top animate__animated animate__zoomIn"
              style="
                margin-left: 0;
                margin-right: 0;
                height: 100%;
                position: relative;
                margin-bottom: 0;
                padding: 0;
                border-radius: 0;
              "
            >
              <div style="height: 100%; position: relative">
                <div class="left-top-1" style="background: #3b51c6" />
                <div
                  class="left-top-2"
                  style="background: #3b51c6; position: absolute; top: 0"
                />
                <div
                  class="themeContent"
                  style="position: absolute; display: flex"
                >
                  <div style="width: 100%">
                    <div
                      style="
                        width: 100%;
                        height: calc(100%);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <img
                        v-if="
                          item.properties.lat &&
                          item.properties.lng &&
                          item.properties.map_url
                        "
                        style="
                          width: auto;
                          height: auto;
                          max-width: calc(100%);
                          max-height: calc(100%);
                        "
                        :src="item.properties.map_url"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="left-top-3"
                  style="background: #3b51c6; position: absolute; right: 0"
                />
                <div
                  class="left-top-2"
                  style="
                    background: #3b51c6;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                  "
                />
              </div>
            </div>

            <div
              v-if="item.type == 'icon-camera' && show"
              class="right_top animate__animated animate__zoomIn"
              style="
                margin-left: 0;
                margin-right: 0;
                height: 100%;
                position: relative;
                margin-bottom: 0;
                padding: 0;
                border-radius: 0;
              "
            >
              <div style="height: 100%; position: relative">
                <div class="left-top-1" style="background: #f5a9a9" />
                <div
                  class="left-top-2"
                  style="background: #f5a9a9; position: absolute; top: 0"
                />
                <div
                  class="themeContent"
                  style="position: absolute; display: flex"
                >
                  <div style="width: 100%">
                    <div
                      style="
                        width: 100%;
                        height: calc(100%);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <img
                        v-if="item.properties.src"
                        style="
                          width: auto;
                          height: auto;
                          max-width: calc(100%);
                          max-height: calc(100%);
                        "
                        :src="item.properties.src"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="left-top-3"
                  style="background: #f5a9a9; position: absolute; right: 0"
                />
                <div
                  class="left-top-2"
                  style="
                    background: #f5a9a9;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                  "
                />
              </div>
            </div>

            <div
              v-if="item.type == 'icon-ai-npc' && show"
              class="right_top animate__animated animate__zoomIn"
              style="
                margin-left: 0;
                margin-right: 0;
                height: 100%;
                position: relative;
                margin-bottom: 0;
                padding: 0;
                border-radius: 0;
                background: none;
                border: none;
                box-shadow: none;
              "
            >
              <div style="height: 100%; position: relative">
                <div
                  :style="
                    isShare
                      ? 'position: absolute;display:flex;height: 294px;width: 480px;bottom: 24px; left: 10px;'
                      : 'position: absolute;display:flex;height: 294px;width: 480px;bottom: 24px; left: 99.5px;'
                  "
                >
                  <div style="width: 100%">
                    <img
                      v-if="hoverClose"
                      src="../../assets/close_pre.png"
                      width="16"
                      height="16"
                      style="
                        position: absolute;
                        right: 10px;
                        top: 5px;
                        z-index: 1029;
                        cursor: pointer;
                      "
                      @mouseout="blurClose"
                      @click="handleClose"
                    />
                    <img
                      v-else
                      src="../../assets/关闭.png"
                      width="16"
                      height="16"
                      style="
                        position: absolute;
                        right: 10px;
                        top: 5px;
                        z-index: 1029;
                        cursor: pointer;
                      "
                      @mouseover="focusClose"
                    />
                    <div
                      style="
                        width: 100%;
                        height: calc(100%);
                        display: flex;
                        flex-direction: column;
                      "
                    >
                      <div
                        id="content"
                        ref="content"
                        class="content"
                        style="
                          width: calc(100% - 32px);
                          padding: 16px;
                          border-radius: 6px;
                          background: linear-gradient(
                            180deg,
                            rgba(0, 0, 0, 0.6) 0%,
                            rgba(0, 69, 108, 0.6) 100%
                          );
                          box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.08);
                          box-shadow: 0px 16px 24px 2px rgba(0, 0, 0, 0.04);
                          box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.05);
                        "
                      >
                        <template
                          v-for="(itemNew, index1) of chat_data"
                          :key="index1"
                        >
                          <div
                            v-if="itemNew.ai"
                            style="
                              display: flex;
                              flex-direction: row;
                              line-height: 20px;
                              margin-bottom: 8px;
                            "
                          >
                            <div
                              style="
                                background: rgba(230, 127, 54, 1);
                                border: 1px solid rgba(230, 127, 54, 1);
                                padding: 0 8px;
                                border-radius: 6px;
                                color: rgba(255, 255, 255, 0.9);
                                font-size: 16px;
                                height: 22px;
                                max-width: calc(20% - 26px);
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                cursor: default;
                              "
                              :title="itemNew.aiName"
                            >
                              {{ itemNew.aiName }}:
                            </div>
                            <div
                              :id="itemNew.uid"
                              class="item_content item_content_other"
                              :style="
                                loading && index1 == chat_data.length - 1
                                  ? 'margin-left:10px;padding:0;background: none;border: none;color: rgba(255, 255, 255, 0.55);font-size: 16px;max-width: 80%;'
                                  : 'margin-left:10px;padding:0;background: none;border: none;color: rgba(255, 215, 187, 1);font-size: 16px;max-width: 80%;'
                              "
                              v-html="itemNew.content"
                            />
                          </div>
                          <div
                            v-else
                            style="
                              display: flex;
                              flex-direction: column;
                              line-height: 20px;
                              margin-bottom: 8px;
                            "
                          >
                            <div style="display: flex; flex-direction: row">
                              <div
                                style="
                                  background: rgba(23, 196, 105, 1);
                                  border: 1px solid rgba(23, 196, 105, 1);
                                  padding: 0 8px;
                                  border-radius: 6px;
                                  color: rgba(255, 255, 255, 0.9);
                                  font-size: 16px;
                                  height: 22px;
                                  max-width: calc(20% - 26px);
                                  white-space: nowrap;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                  cursor: default;
                                "
                                :title="itemNew.aiName"
                              >
                                {{ itemNew.aiName }}:
                              </div>
                              <div
                                class="item_content item_content_other item-myself"
                                style="
                                  margin-left: 10px;
                                  padding: 0;
                                  background: none;
                                  border: none;
                                  font-size: 16px;
                                  color: rgba(168, 237, 201, 1);
                                  max-width: 80%;
                                "
                              >
                                {{ itemNew.content }}
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                      <div
                        id="input"
                        class="input flex_x"
                        style="position: relative; margin-top: 4px"
                      >
                        <el-input
                          ref="chat_input_new"
                          v-model="chat_content"
                          type="textarea"
                          :autosize="{ minRows: 1, maxRows: 10 }"
                          :placeholder="languageData.searchImagesDesc"
                          :clearable="true"
                          :autofocus="false"
                          :disabled="disabled"
                          @input="handle_chat_content_change"
                          @keyup.enter="send_message"
                          @focus="handle_focus"
                          @blur="handle_blur"
                        />
                        <el-button
                          :disabled="disabled"
                          class="send_icon"
                          @click="send_message"
                          >{{ languageData.sendButton }}</el-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import $ from "jquery";
import "animate.css";
import { v4 as uuidv4 } from "uuid";
import { formatDateTime } from "../../tools/utils.js";
import MarkdownIt from "markdown-it";
import {
  showUserMessage,
  showAIMessage,
  showTipMessage,
  destoryTipMessage,
  setIsTip,
  showUserTipMessage,
  destoryUserTipMessage,
} from "../../tools/spriteUtilities";
export default {
  props: {
    list: { type: Array, default: undefined },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/prop-name-casing
    show: Boolean,
    isShare: {
      type: Boolean,
      default: true,
    },
    userId: {
      type: String,
      default: "",
    },
  },
  emits: ["info"],
  data() {
    return {
      chat_data: [],
      chat_content: "",
      hoverMax: false,
      hoverRecovery: false,
      fullscreen: false,
      hoverClose: false,
      // disX: 0,
      disY: 0,
      down: false,
      letterList: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      Radio: "",
      showClose: false,
      isDrag: true,
      isPhone: false,
      screenWidth: null,
      loading: false,
      time: null,
      disabled: false,
    };
  },
  watch: {
    list() {
      // let that = this;
      console.log(this.list);
      if (this.list.length != 0) {
        $(".el-dialog").css("width", "");
        // let fontsize = '';
        this.isDrag = true;
        localStorage.setItem("isDrag", this.isDrag);
        for (let i = 0; i < this.list.length; i++) {
          // if (this.list[i].properties.radio == 'content') {
          //     let len = this.list[i].properties.contentLength;
          //     // console.log(len)
          //     if (0 < len && len == 1) {
          //         fontsize = "160px";
          //     }
          //     else if (1 < len && len <= 3) {
          //         fontsize = "80px";
          //     }
          //     else if (3 < len && len <= 5) {
          //         fontsize = "40px";
          //     }
          //     else if (5 < len && len <= 7) {
          //         fontsize = "20px";
          //     }
          //     else if (7 < len && len <= 9) {
          //         fontsize = "12px";
          //     }
          // }
          if (this.list[i].properties.radio == "videoList") {
            $(".el-dialog").css({
              height: "425px",
            });
            this.isDrag = false;
          } else if (this.list[i].properties.radio == "imageList") {
            this.isDrag = false;
          } else if (this.list[i].properties.prologue) {
            this.chat_data = [];
            this.chat_data.push({
              userName: "",
              userId: "",
              content: this.list[i].properties.prologue,
              time: formatDateTime(new Date().getTime()),
              ai: true,
              is_mind_map: false,
              index: this.chat_data.length,
              aiName: this.list[i].properties.name,
            });
            this.$nextTick(() => {
              $("#showResult .el-dialog").css("width", "100%");
              $("#showResult .el-dialog__body").css("height", "100%");
            });
          }
          localStorage.setItem("isDrag", this.isDrag);
          document.addEventListener("keydown", this.handleKeyDown);
        }
        this.$nextTick(() => {
          $(".el-dialog").on("mouseup", () => {
            this.down = false;
            document.onmousemove = null;
            document.onmousedown = null;
            document.onmouseup = null;
          });
          $(".el-dialog").on("mousemove", (e) => {
            // 获取鼠标的实时位置
            // const curX = e.clientX
            const curY = e.clientY;
            // 获取拖拽的长度
            // const increaseX = curX - this.disX
            const increaseY = curY - this.disY;
            // 按下开始拖拽改变目标元素大小
            if (this.down) {
              // const width = this.$refs.handle.offsetWidth + increaseX
              const height = this.$refs.handle.offsetHeight + increaseY;
              $(e.currentTarget).css({
                // "width": width < parseInt(parseInt($(".diagram-main").width()) * 0.52) ? '52%' : width + 'px',
                height:
                  height < 425
                    ? "425px"
                    : 425 <= height &&
                      (height / parseInt($(".diagram-main").height())) * 100 <=
                        80
                    ? height + "px"
                    : "80%",
              });
            }
          });
          $(".el-dialog__header").on("mouseover", () => {
            this.showClose = true;
          });
          $(".el-dialog__header").on("mouseleave", () => {
            this.showClose = false;
          });
        });
      }
    },
    screenWidth(newVal) {
      if (newVal > 960) {
        this.isPhone = false;
      } else {
        $("#leftSide").css("display", "none");
        $("#scroll-box").css("width", "calc(100%)");
        $("#scroll-box").css("border-bottom-left-radius", "12px");
        this.isPhone = true;
      }
    },
  },
  mounted() {
    if (window.location.href.indexOf("cocorobo.cn") != -1) {
      this.languageData = this.zhHansLanguage;
    } else if (window.location.href.indexOf("cocorobo.hk") != -1) {
      this.languageData = this.zhHantLanguage;
    } else if (window.location.href.indexOf("cocorobo.com") != -1) {
      this.languageData = this.enLanguage;
    } else {
      this.languageData = this.zhHansLanguage;
    }
    this.screenWidth = window.screen.width;
    window.onresize = () => {
      this.screenWidth = window.screen.width;
    };
    this.$nextTick(() => {
      $(".el-dialog").on("mouseup", () => {
        this.down = false;
        document.onmousemove = null;
        document.onmousedown = null;
        document.onmouseup = null;
      });
      $(".el-dialog").on("mousemove", (e) => {
        // console.log($(".diagram-main").width())
        e.preventDefault();
        // 获取鼠标的实时位置
        // const curX = e.clientX
        const curY = e.clientY;
        // 获取拖拽的长度
        // const increaseX = curX - this.disX
        const increaseY = curY - this.disY;
        // 按下开始拖拽改变目标元素大小
        if (this.down) {
          // const width = this.$refs.handle.offsetWidth + increaseX
          const height = this.$refs.handle.offsetHeight + increaseY;
          $(e.currentTarget).css({
            height:
              height < 425
                ? "425px"
                : 425 <= height &&
                  (height / parseInt($(".diagram-main").height())) * 100 <= 80
                ? height + "px"
                : "80%",
          });
        }
      });
    });
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    focusClose() {
      this.hoverClose = true;
    },
    blurClose() {
      this.hoverClose = false;
    },
    handleKeyDown(event) {
      if (event.key === "Escape") {
        // 检查按下的是否是Esc键
        // 处理Esc键按下的逻辑
        this.handleClose();
      }
    },
    handle_chat_content_change(value) {
      if (this.$refs.chat_input_new.$el) {
        const scrollElement = this.$refs.chat_input_new.$el.querySelector(
          ".el-textarea__inner"
        );
        scrollElement.scrollTop = scrollElement.scrollHeight;
      }
      console.log(value);
    },
    handle_focus() {
      let that = this;
      if (that.chat_data.at(-1).ai == false) {
        that.chat_data.at(-1).content = that.languageData.tip115;
        that.$nextTick(() => {
          document.getElementById("content").scrollTop =
            document.getElementById("content").scrollHeight;
        });
        if (!that.isPhone && that.isShare) {
          showUserTipMessage(that.chat_data.at(-1).content);
        }
        let temp = 0;
        that.time = setInterval(function () {
          that.$nextTick(() => {
            document.getElementById("content").scrollTop =
              document.getElementById("content").scrollHeight;
          });
          if (temp == 3) {
            that.chat_data.at(-1).content = that.languageData.tip115;
            if (!that.isPhone && that.isShare) {
              destoryUserTipMessage();
              showUserTipMessage(that.chat_data.at(-1).content);
            }
            temp = 0;
            that.chat_data.at(-1).content = that.chat_data.at(-1).content + ".";
            if (!that.isPhone && that.isShare) {
              destoryUserTipMessage();
              showUserTipMessage(that.chat_data.at(-1).content);
            }
            temp = temp + 1;
          } else {
            that.chat_data.at(-1).content = that.chat_data.at(-1).content + ".";
            if (!that.isPhone && that.isShare) {
              destoryUserTipMessage();
              showUserTipMessage(that.chat_data.at(-1).content);
            }
            temp = temp + 1;
          }
        }, 1000);
      } else {
        that.chat_data.push({
          userName:
            window.location.href.indexOf("localhost") != -1 ||
            window.location.href.indexOf("192.168") != -1
              ? "潘炜桐"
              : "用户",
          userId:
            window.location.href.indexOf("localhost") != -1 ||
            window.location.href.indexOf("192.168") != -1
              ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
              : "",
          content: that.languageData.tip115,
          ai: false,
          is_mind_map: false,
          index: that.chat_data.length,
          aiName: that.languageData.tip117,
        });
        that.$nextTick(() => {
          document.getElementById("content").scrollTop =
            document.getElementById("content").scrollHeight;
        });
        if (!that.isPhone && that.isShare) {
          showUserTipMessage(that.chat_data.at(-1).content);
        }
        let temp = 0;
        that.time = setInterval(function () {
          that.$nextTick(() => {
            document.getElementById("content").scrollTop =
              document.getElementById("content").scrollHeight;
          });
          if (temp == 3) {
            that.chat_data.at(-1).content = that.languageData.tip115;
            if (!that.isPhone && that.isShare) {
              destoryUserTipMessage();
              showUserTipMessage(that.chat_data.at(-1).content);
            }
            temp = 0;
            that.chat_data.at(-1).content = that.chat_data.at(-1).content + ".";
            if (!that.isPhone && that.isShare) {
              destoryUserTipMessage();
              showUserTipMessage(that.chat_data.at(-1).content);
            }
            temp = temp + 1;
          } else {
            that.chat_data.at(-1).content = that.chat_data.at(-1).content + ".";
            if (!that.isPhone && that.isShare) {
              destoryUserTipMessage();
              showUserTipMessage(that.chat_data.at(-1).content);
            }
            temp = temp + 1;
          }
        }, 1000);
      }
    },
    handle_blur() {
      let that = this;
      clearInterval(that.time);
      if (!that.isPhone && that.isShare) {
        destoryUserTipMessage();
      }
    },
    send_message() {
      let that = this;
      // that.$refs.chat_input_new.$refs.textarea.blur()
      if (that.chat_content == "") {
        that.$message({
          message: that.languageData.searchImagesDesc,
          type: "warning",
        });
        return;
      } else {
        clearInterval(that.time);
        if (!that.isPhone && that.isShare) {
          destoryUserTipMessage();
        }
        document
          .getElementById("input")
          .getElementsByTagName("textarea")[0]
          .blur();
        that.disabled = true;
        that.chat_data.at(-1).content = that.chat_content;
        that.$nextTick(() => {
          document.getElementById("content").scrollTop =
            document.getElementById("content").scrollHeight;
        });
        if (!that.isPhone && that.isShare) {
          showUserMessage(that.chat_content);
        }
        let uid = uuidv4();
        var config = {
          method: "POST",
          url: "https://gpt4.cocorobo.cn/ai_agent_chat_new",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            id: that.list[0].properties.id,
            userId: that.userId,
            message: that.chat_content,
            uid: uid,
          },
        };
        that.chat_content = "";
        that.loading = true;
        that.chat_data.push({
          userName: "",
          userId: "",
          content: that.languageData.tip84,
          time: formatDateTime(new Date().getTime()),
          ai: true,
          is_mind_map: false,
          index: this.chat_data.length,
          aiName: that.list[0].properties.name,
          uid: uid,
        });
        that.$nextTick(() => {
          document.getElementById("content").scrollTop =
            document.getElementById("content").scrollHeight;
        });
        that
          .$ajax(config)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
        that.askAssistant(uid);
      }
    },
    askAssistant(uid) {
      let that = this;
      if (this.ai_source) {
        this.ai_source.close();
      }
      this.ai_source = new EventSource(
        "https://gpt4.cocorobo.cn/question/" + uid
      );
      let alltext = "";
      let newalltext = "";
      const md = new MarkdownIt();
      let temp = 0;
      let time = setInterval(function () {
        that.$nextTick(() => {
          document.getElementById("content").scrollTop =
            document.getElementById("content").scrollHeight;
        });
        if (temp == 3) {
          that.chat_data.at(-1).content = that.languageData.tip84;
          if (!that.isPhone && that.isShare) {
            destoryTipMessage();
            showTipMessage(that.list[0], that.chat_data.at(-1).content);
          }
          temp = 0;
          that.chat_data.at(-1).content = that.chat_data.at(-1).content + ".";
          if (!that.isPhone && that.isShare) {
            destoryTipMessage();
            showTipMessage(that.list[0], that.chat_data.at(-1).content);
          }
          temp = temp + 1;
        } else {
          that.chat_data.at(-1).content = that.chat_data.at(-1).content + ".";
          if (!that.isPhone && that.isShare) {
            destoryTipMessage();
            showTipMessage(that.list[0], that.chat_data.at(-1).content);
          }
          temp = temp + 1;
        }
      }, 1000);
      //上传新文档的时候问题先隐藏掉
      this.ai_source.onmessage = function (e) {
        that.loading = false;
        clearInterval(time);
        destoryTipMessage();
        let eData = JSON.parse(e.data);
        if (eData.content.replace("'", "").replace("'", "") == "[DONE]") {
          newalltext = newalltext.replace("_", "");
          newalltext = newalltext.replace("_", "");
          that.chat_data.at(-1).content = newalltext;
          that.disabled = false;
          if (that.isShare) {
            showAIMessage(
              that.list[0],
              $("#" + uid)
                .text()
                .replace("_", "")
            );
          }
          //重置数据
          that.ai_source.close();
          alltext = "";
          newalltext = "";
          return;
        } else {
          let a = eData.content.replace("'", "").replace("'", "");
          that.prevChatResult = that.prevChatResult + a;
          if (alltext == "") {
            alltext = a.replace(/^\n+/, ""); //去掉回复消息中偶尔开头就存在的连续换行符
          } else {
            alltext += a;
          }
          newalltext = alltext + "_";
          newalltext = newalltext.replace(/\\n/g, "\n");
          newalltext = newalltext.replace(/\\/g, "");
          if (alltext.split("```").length % 2 == 0) newalltext += "\n```\n";
          newalltext = md.render(newalltext);
          that.chat_data.at(-1).content = newalltext;
          if (!that.isPhone && that.isShare) {
            showAIMessage(
              that.list[0],
              $("#" + uid)
                .text()
                .replace("_", "")
            );
          }
          that.$nextTick(() => {
            document.getElementById("content").scrollTop =
              document.getElementById("content").scrollHeight;
          });
        }
      };
    },
    handleClose() {
      let that = this;
      this.clickRecovery();
      this.Radio = "";
      this.chat_content = "";
      this.$emit("info", false);
      if (!that.isPhone) {
        clearInterval(this.time);
        destoryUserTipMessage();
      }
      setIsTip();
    },
    // down置为true，记录鼠标点下的位置
    handleMousedown(e) {
      this.down = true;
      this.disY = e.clientY - this.$refs.handle.offsetTop;
    },
    focusMax() {
      this.hoverMax = true;
      this.hoverRecovery = false;
    },
    blurMax() {
      this.hoverMax = false;
      this.hoverRecovery = false;
    },
    focusRecovery() {
      this.hoverMax = false;
      this.hoverRecovery = true;
    },
    blurRecovery() {
      this.hoverMax = false;
      this.hoverRecovery = false;
    },
    clickMax() {
      this.fullscreen = true;
      $("#showResult .el-dialog__body").css({
        height: "87.5%",
      });
    },
    clickRecovery() {
      this.fullscreen = false;
      $("#showResult .el-dialog__body").css({
        height: "100%",
      });
    },
    setSelect(e) {
      this.Radio = e;
    },
  },
};
</script>

<style scoped>
.send_icon {
  width: 52px;
  height: 30px;
  position: absolute;
  bottom: 12px;
  right: 12px;
  cursor: pointer;
  padding: 4px 12px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.22);
  color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.22);
}

.send_icon[disabled] {
  cursor: not-allowed;
  opacity: 0.65;
}

.send_icon[disabled]:hover {
  background: rgba(255, 255, 255, 0.22);
  border: 1px solid rgba(255, 255, 255, 0.22);
  color: rgba(255, 255, 255, 0.9);
}

.send_icon:not([disabled]):hover {
  border: 1px solid rgba(54, 129, 252, 1);
  background: rgba(54, 129, 252, 1);
}

.content {
  width: 100%;
  flex: 1;
  overflow-y: auto;
  padding: 10px 0px;
  line-height: 32px;
  position: relative;
}

.item_content {
  max-width: 80%;
  padding: 8px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #cdcdcd;
  line-height: 20px;
  background: #f3f7f3;
  word-wrap: break-word;
}
.item_content_other {
  background: #f3f7f3;
  padding: 12px;
  border-radius: 2px 8px 8px 8px;
}

.item_content_other :deep() p {
  margin-top: 0;
  margin-bottom: 0;
}

.embedCode :deep() iframe {
  width: 100%;
  height: 90%;
}

#showResult #content1 {
  color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6));
  font-family: SimSun;
  font-style: normal;
  font-weight: 400;
  line-height: 22px !important;
  font-size: 20px !important;
}

#showResult #content1 p {
  color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6));
  font-family: SimSun;
  font-style: normal;
  font-weight: 400;
  line-height: 22px !important;
  font-size: 20px !important;
  margin-top: 0;
  margin-bottom: 0;
}

#showResult #content1 p span {
  color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6));
  font-family: SimSun;
  font-style: normal;
  font-weight: 400;
  line-height: 22px !important;
  font-size: 20px !important;
}

#showResult #content2 {
  color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6));
  font-family: SimSun;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 22px !important;
}

#showResult #content2 p {
  color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6));
  font-family: SimSun;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 22px !important;
  margin-top: 0;
  margin-bottom: 0;
}

#showResult #content2 p span {
  color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6));
  font-family: SimSun;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 22px !important;
}

#showResult .text {
  color: #fff;
  text-align: center;
  font-family: SimSun;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 2.76px;
}

.left-top-1 {
  width: 30px;
  height: 200px;
}

.left-top-2 {
  width: 200px;
  height: 30px;
}

.left-top-3 {
  width: 30px;
  height: 170px;
  bottom: 30px;
}

.themeContent {
  top: 140px;
  left: 240px;
  height: calc(100% - 280px);
  width: calc(100% - 480px);
}

.themeText {
  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
  text-align: center;
  font-family: SimSun;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 6.36px;
}

.themeDiv {
  width: 100px;
  height: 40px;
  margin-bottom: 24px;
}

.stageContent_1 {
  top: 150px;
  left: 100px;
  height: calc(100% - 300px);
  width: calc(100% - 200px);
}

.stageContent_2 {
  top: 150px;
  left: 100px;
  height: calc(100% - 300px);
  width: calc(100% - 200px);
}

.stageName {
  margin-bottom: 32px;
  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
  text-align: center;
  font-family: SimSun;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 5.6px;
}

.taskName {
  margin-bottom: 16px;
  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
  font-family: SimSun;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 3.68px;
}

.taskText {
  color: #fff;
  text-align: center;
  font-family: SimSun;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 2.76px;
}

.cardContent {
  top: 140px;
  left: 128px;
  height: calc(100% - 280px);
  width: calc(100% - 256px);
}

@media screen and (max-width: 960px) {
  .left-top-1 {
    width: 25px;
    height: 175px;
  }

  .left-top-2 {
    width: 175px;
    height: 25px;
  }

  .left-top-3 {
    width: 25px;
    height: 175px;
    bottom: 25px;
  }

  .themeText {
    color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
    text-align: center;
    font-family: SimSun;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 128.571% */
    letter-spacing: 3.36px;
  }

  .themeContent {
    top: 71px;
    left: 50px;
    height: calc(100% - 142px);
    width: calc(100% - 100px);
  }

  .themeDiv {
    width: 110px;
    height: 33px;
    margin-bottom: 15px;
  }

  .stageContent_1 {
    top: 71px;
    left: 50px;
    height: calc(100% - 142px);
    width: calc(100% - 100px);
  }

  .stageContent_2 {
    top: 71px;
    left: 50px;
    height: calc(100% - 142px);
    width: calc(100% - 100px);
  }

  #showResult .text {
    color: #fff;
    text-align: center;
    font-family: SimSun;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 2.88px;
  }

  .stageName {
    margin-bottom: 12.5px;
    color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
    text-align: center;
    font-family: SimSun;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 3.36px;
  }

  #showResult #content1 {
    color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6));
    font-family: SimSun;
    font-style: normal;
    font-weight: 400;
    line-height: 20px !important;
    font-size: 14px !important;
  }

  #showResult #content1 p {
    color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6));
    font-family: SimSun;
    font-style: normal;
    font-weight: 400;
    line-height: 20px !important;
    font-size: 14px !important;
    margin-top: 0;
    margin-bottom: 0;
  }

  #showResult #content1 p span {
    color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6));
    font-family: SimSun;
    font-style: normal;
    font-weight: 400;
    line-height: 20px !important;
    font-size: 14px !important;
  }

  .taskName {
    margin-bottom: 12.5px;
    color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
    font-family: SimSun;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 3.36px;
  }

  .taskText {
    color: #fff;
    text-align: center;
    font-family: SimSun;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 2.88px;
  }

  #showResult #content2 {
    color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6));
    font-family: SimSun;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px !important;
  }

  #showResult #content2 p {
    color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6));
    font-family: SimSun;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px !important;
    margin-top: 0;
    margin-bottom: 0;
  }

  #showResult #content2 p span {
    color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6));
    font-family: SimSun;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px !important;
  }

  .cardContent {
    top: 54px;
    left: 77px;
    height: calc(100% - 130px);
    width: calc(100% - 154px);
  }
}

.loading_div {
  width: 200px;
  height: 40px;
}

.item-myself {
  font-family: SimSun;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding: 12px;
  border-radius: 8px 2px 8px 8px;
  /* margin-right: 10px; */
}
</style>

<style>
.showResult .CloseImgTheme {
  top: 8px;
  right: 5px;
  font-size: 20px;
  position: absolute;
  cursor: pointer;
  color: #000000;
}
.showResult .CloseImgTheme:hover,
.showResult .CloseImgTheme:active {
  color: #fe7777;
}
#showResult .el-dialog__wrapper {
  display: flex;
  align-items: center;
}
#showResult .el-dialog__header,
#showFileListResult .el-dialog__header,
#showImageListResult .el-dialog__header {
  display: none;
}

#showClose .el-dialog__header {
  display: block;
}

#showResult .el-dialog__body,
#showImageListResult .el-dialog__body {
  padding: 0;
  background: none;
}

#showFileListResult .el-dialog__body {
  padding: 30px 10px;
}

#showResult .el-dialog,
#showFileListResult .el-dialog,
#showImageListResult .el-dialog {
  background: none;
  box-shadow: none;
  height: 100% !important;
  width: 60%;
}

.max {
  position: absolute;
  top: 20px;
  right: 40px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}

.el-dialog.is-fullscreen {
  margin-top: 0 !important;
}

#showResult .el-dialog,
#showImageListResult .el-dialog {
  margin-top: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

#showFileListResult .el-dialog {
  width: 98% !important;
  /* margin-top: 0!important; */
  height: 90% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

#showResult .el-dialog__body i:hover {
  color: #fe7777;
}

#showFileListResult .el-dialog__body i:hover {
  color: #fe7777;
}

#showImageListResult .el-dialog__body i:hover {
  color: #fe7777;
}

#showResult .el-dialog__body {
  /* height: 100%; */
  width: 100%;
  position: relative;
  height: 60%;
}

#showFileListResult .el-dialog__body {
  height: 100%;
  width: 100%;
  position: relative;
}

#showImageListResult .el-dialog__body {
  /* height: 100%; */
  /* width: 100%; */
  position: relative;
}

.el-dialog__header {
  width: 96.5%;
}

#handle {
  width: 100%;
  height: 20px;
  /* background-color: red; */
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  cursor: s-resize;
}

#showResult .el-loading-mask {
  background: none;
}

#showResult .input .el-textarea__inner {
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(54, 129, 252, 1);
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 16px 24px 2px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #ffffff;
  font-size: 14px;
  border-radius: 8px;
  padding: 14px 75px 14px 12px;
}

#showResult .input .el-textarea__inner::-webkit-input-placeholder {
  /* Chrome/Safari/Opera */
  color: rgba(255, 255, 255, 0.35);
}
#showResult .input .el-textarea__inner:-moz-placeholder {
  /* Firefox 4 - 18 */
  color: rgba(255, 255, 255, 0.35);
}
#showResult .input .el-textarea__inner::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.35);
  opacity: 1;
}
#showResult .input .el-textarea__inner:-ms-input-placeholder {
  /* IE 10+ 注意这里只有一个冒号 */
  color: rgba(255, 255, 255, 0.35);
}
</style>
