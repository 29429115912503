import { HtmlNodeModel, HtmlNode } from '@logicflow/core';
// import { HtmlResize } from "@logicflow/extension";
import "../../../uml.css";
// import img_pre from '../../../assets/取消.png'
import img_loading from "../../../assets/loading.gif";
import img_nor from "../../../assets/追加.png";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { sendUpdate, add_Operation } from "../../../tools/send.js";
import { zhHansLanguage } from "../../../lang/zh-Hans.js";
import { zhHantLanguage } from "../../../lang/zh-Hant.js";
import { enLanguage } from "../../../lang/en.js";
import { ElMessageBox as MessageBox } from "element-plus";

class AICardModel extends HtmlNodeModel {
  initNodeData(data) {
    super.initNodeData(data);
    this.text.editable = false; // 禁止节点文本编辑
    const width =
      data.properties.nodeSize && data.properties.nodeSize.width
        ? data.properties.nodeSize.width
        : 250;
    const height =
      data.properties.nodeSize && data.properties.nodeSize.height
        ? data.properties.nodeSize.height
        : 250;
    this.width = width;
    this.height = height;
    this.minWidth = 250;
    this.minHeight = 250;
    this.gridSize = 1;

    const unable_to_connect_with_oneself = {
      message:
        window.location.href.indexOf("cocorobo.cn") != -1
          ? zhHansLanguage.tip43
          : window.location.href.indexOf("cocorobo.hk") != -1
          ? zhHantLanguage.tip43
          : enLanguage.tip43,
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceAnchor, targetAnchor);
        return targetNode.id != sourceNode.id;
      },
    };
    this.sourceRules.push(unable_to_connect_with_oneself);
    const unable_to_connect_with_theme = {
      message:
        window.location.href.indexOf("cocorobo.cn") != -1
          ? zhHansLanguage.unable_to_connect_with_theme
          : window.location.href.indexOf("cocorobo.hk") != -1
          ? zhHantLanguage.unable_to_connect_with_theme
          : enLanguage.unable_to_connect_with_theme,
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceNode, sourceAnchor, targetAnchor);
        return targetNode.type != "icon-theme";
      },
    };
    this.sourceRules.push(unable_to_connect_with_theme);
    const unable_to_connect_with_other = {
      message:
        window.location.href.indexOf("cocorobo.cn") != -1
          ? zhHansLanguage.unable_to_connect_with_other
          : window.location.href.indexOf("cocorobo.hk") != -1
          ? zhHantLanguage.unable_to_connect_with_other
          : enLanguage.unable_to_connect_with_other,
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceAnchor, targetAnchor);
        let edges = this.graphModel.getNodeOutgoingEdge(sourceNode.id);
        let isConnect = false;
        for (let i = 0; i < edges.length; i++) {
          if (
            edges[i].sourceNodeId == sourceNode.id &&
            edges[i].targetNodeId == targetNode.id
          ) {
            isConnect = true;
            break;
          }
        }
        return isConnect == false;
      },
    };
    this.sourceRules.push(unable_to_connect_with_other);
  }
  // getResizeOutlineStyle(){
  //   const style = super.getResizeOutlineStyle();
  //   style.stroke = "#007BFF";
  //   style.strokeWidth = 2
  //   style.strokeDasharray = "3,3"
  //   style.fill = "transparent"
  //   return style;
  // }
  setAttributes() {
    if (
      (this.properties.nodeSize &&
        this.properties.nodeSize.height &&
        parseInt(this.properties.nodeSize.height) == 250) ||
      (this.properties.nodeSize &&
        this.properties.nodeSize.width &&
        parseInt(this.properties.nodeSize.width) == 250)
    ) {
      this.width = 250;
      this.height = 250;
    }
  }
  getData() {
    const data = super.getData();
    let properties = data.properties;
    properties = {
      ...properties,
      nodeSize: {
        width: this.width,
        height: this.height,
      }
    }
    return {
      ...super.getData(),
      properties: properties
    }
  }
}
class AICardNode extends HtmlNode {
  contentTitle(eleDom, textTips, topic, lf, isShowDescribe) {
    let keyword = [];
    const itemDom = document.createElement("div"); //增加提个DOM p标签，用于展示 自定义title
    itemDom.setAttribute("class", "AI_append hover"); //添加class命名，用于设置自定义title样式
    itemDom.style = "left:104%;top:80%;";
    const div = document.createElement("div");
    div.style =
      "width:30px;height:30px;display: flex;flex-direction: row;align-items: center;justify-content: center;";

    const img = document.createElement("img");
    img.src = img_nor;
    div.appendChild(img);
    itemDom.appendChild(div);

    eleDom.parentNode.style = "overflow: visible;";
    let itemClickDomShow = isShowDescribe;
    const p = document.createElement("div");
    let divs = [];

    // 点击后也设置为不显示自定义title
    const eleClick = function (e) {
      if (itemClickDomShow) {
        itemDom.removeChild(p);
        itemDom.setAttribute("class", "AI_append hover");
        itemClickDomShow = false;
        img.src = img_nor;
        itemDom.append(div);
        itemDom.style = "border: 1px solid #ffffff;left:104%;top:80%;";
        div.style =
          "width:30px;height:30px;display: flex;flex-direction: row;align-items: center;justify-content: center;";
        lf.graphModel.getNodeModelById(lf.model.id).setProperties({
          isShowDescribe: false,
        });
        let nodes = [];
        nodes.push(lf.graphModel.getNodeModelById(lf.model.id).getData());
        sendUpdate(nodes, [], "update", 0);
      } else {
        if (textTips != "") {
          for (let i = 0; i < divs.length; i++) {
            p.removeChild(divs[i]);
          }
          const loadImg = document.createElement("img");
          loadImg.src = img_loading;
          p.appendChild(loadImg);
          axios
            .post("https://gpt.cocorobo.cn/getMoreKeyword", {
              alltext: textTips,
            })
            .then(function (response) {
              p.removeChild(loadImg);
              let result = response.data.FunctionResponse.questions_result;
              result = result.replace(/\s/g, "").replace(/\n/g, "");
              let newResult = result.split(";");
              newResult.sort(function (a, b) {
                return a.length - b.length;
              });
              // p.textContent = result; //参数textTips 代表需要展示的自定义title文本
              p.style = "margin-left:10px;margin-right:10px;";

              const eClick = function (e) {
                // console.log(e.target.innerText)
                let id = uuidv4();
                let nodes = {
                  id: id,
                  type: "icon-AI",
                  properties: {
                    type: "icon-AI",
                    topic: "智慧课堂",
                    keyWordList: keyword,
                    currentKeyWord: e.target.innerText,
                    isLoading: true,
                    aiContent: "",
                  },
                  x: lf.model.x + 500,
                  y: lf.model.y,
                  zIndex: 1002,
                };
                lf.graphModel.addNode(nodes);
                axios
                  .post("https://gpt.cocorobo.cn/askForKeyword", {
                    topic: topic,
                    keyword: e.target.innerText,
                  })
                  .then(function (response) {
                    lf.graphModel.getNodeModelById(id).setProperties({
                      isLoading: false,
                      aiContent: response.data.FunctionResponse.result,
                    });
                    let newNodes = [];
                    newNodes.push(lf.graphModel.getNodeModelById(id).getData());
                    sendUpdate(newNodes, [], "update", 0);
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
                e.stopPropagation();
              };
              divs = [];
              const keyWordDiv = document.createElement("div");
              keyWordDiv.style =
                "font-family: SimSun;font-weight: bold;color: #060E17;margin-bottom:5px;";
              keyWordDiv.textContent = "关键词";
              p.append(keyWordDiv);
              for (let i = 0; i < newResult.length; i++) {
                keyword.push(newResult[i]);
                const newDiv = document.createElement("div");
                const a = document.createElement("button");
                a.className =
                  "el-button run el-button--primary el-button--mini keyword";
                a.style =
                  "margin-top:5px;margin-bottom:5px;border:1px solid #B8E4DF;border-radius: 2px 2px 2px 2px;opacity: 1;";
                a.textContent = newResult[i];
                a.addEventListener("click", eClick);
                newDiv.appendChild(a);
                p.appendChild(newDiv);
                divs.push(newDiv);
              }
              lf.graphModel.getNodeModelById(lf.model.id).setProperties({
                isShowDescribe: true,
                appendResult: keyword,
              });
              let oldNodes = [];
              oldNodes.push(
                lf.graphModel.getNodeModelById(lf.model.id).getData()
              );
              sendUpdate(oldNodes, [], "update", 0);
            })
            .catch(function (error) {
              console.log(error);
            });
          itemDom.appendChild(p);
          itemDom.removeChild(div);
          itemDom.setAttribute("class", "AI_append");
          // img.src = img_pre;
          const closeImg = document.createElement("i");
          closeImg.className = "el-icon-circle-close closeImg";
          // closeImg.src = img_pre;
          closeImg.addEventListener("click", eleClick);
          itemDom.appendChild(closeImg);
          itemClickDomShow = true;
          itemDom.style =
            "border: 1px solid #58ABA2;height:auto;text-align:center;left:104%;top:80%;padding:8px 15px;";
          div.style =
            "width:30px;height:30px;display: flex;flex-direction: row;align-items: center;justify-content: center;border-bottom-left-radius: 6px;border-top-left-radius: 6px;border: 1px solid rgb(0, 123, 255);";
        }
      }
      e.stopPropagation();
    };

    // div.addEventListener("mouseenter", eleMouseenter);
    // div.addEventListener("mouseleave", eleMouseleave);
    div.addEventListener("click", eleClick);

    if (itemClickDomShow) {
      // p.textContent = textTips; //参数textTips 代表需要展示的自定义title文本
      p.style = "margin-left:10px;margin-right:10px;";

      divs = [];
      let appendResult = lf.model.properties.appendResult
        ? lf.model.properties.appendResult
        : [];

      if (appendResult.length != 0) {
        appendResult.sort(function (a, b) {
          return a.length - b.length;
        });
      }

      let eClicks = function (e) {
        // console.log(e.target.innerText)
        let id = uuidv4();
        let nodes = {
          id: id,
          type: "icon-AI",
          properties: {
            type: "icon-AI",
            topic: "智慧课堂",
            keyWordList: keyword,
            currentKeyWord: e.target.innerText,
            isLoading: true,
            aiContent: "",
          },
          x: lf.model.x + 500,
          y: lf.model.y,
          zIndex: 1002,
        };
        lf.graphModel.addNode(nodes);
        axios
          .post("https://gpt.cocorobo.cn/askForKeyword", {
            topic: topic,
            keyword: e.target.innerText,
          })
          .then(function (response) {
            lf.graphModel.getNodeModelById(id).setProperties({
              isLoading: false,
              aiContent: response.data.FunctionResponse.result,
            });
            let newNodes = [];
            newNodes.push(lf.graphModel.getNodeModelById(id).getData());
            sendUpdate(newNodes, [], "update", 0);
          })
          .catch(function (error) {
            console.log(error);
          });
        e.stopPropagation();
      };
      const keyWordDiv = document.createElement("div");
      keyWordDiv.style =
        "font-family: SimSun;font-weight: bold;color: #060E17;margin-bottom:5px;";
      keyWordDiv.textContent = "关键词";
      p.append(keyWordDiv);
      for (let i = 0; i < appendResult.length; i++) {
        keyword.push(appendResult[i]);
        const newDiv = document.createElement("div");
        const a = document.createElement("button");
        a.className =
          "el-button run el-button--primary el-button--mini keyword";
        a.style =
          "margin-top:5px;margin-bottom:5px;border:1px solid #B8E4DF;border-radius: 2px 2px 2px 2px;opacity: 1;";
        a.textContent = appendResult[i];
        a.addEventListener("click", eClicks);
        newDiv.appendChild(a);
        p.appendChild(newDiv);
        divs.push(newDiv);
      }

      itemDom.appendChild(p);
      // img.src = img_pre;
      itemDom.removeChild(div);
      itemDom.setAttribute("class", "AI_append");
      const closeImg = document.createElement("i");
      closeImg.className = "el-icon-circle-close closeImg";
      closeImg.addEventListener("click", eleClick);
      // closeImg.src = img_pre;
      itemDom.appendChild(closeImg);
      itemClickDomShow = true;
      itemDom.style =
        "border: 1px solid #58ABA2;height:auto;text-align:center;left:104%;top:80%;padding:8px 15px;";
      div.style =
        "width:30px;height:30px;display: flex;flex-direction: row;align-items: center;justify-content: center;border-bottom-left-radius: 6px;border-top-left-radius: 6px;border: 1px solid rgb(0, 123, 255);";
    }

    eleDom.appendChild(itemDom);
  }
  setHtml(rootEl) {
    const { id, properties } = this.props.model;

    // var r = Math.floor(Math.random() * 256);
    // var g = Math.floor(Math.random() * 256);
    // var b = Math.floor(Math.random() * 256);
    // var color = "rgb(" + r + "," + g + "," + b + ")";
    var color =
      properties.AITitleColor && properties.AITitleColor != ""
        ? properties.AITitleColor
        : "#8EBDB0";

    const el = document.createElement("div");
    el.className = "uml-wrapper";
    // el.style = "border: 1px solid " + color + ';';
    var html = ``;
    let cardTitle =
      window.location.href.indexOf("cocorobo.cn") != -1
        ? zhHansLanguage.aiCard
        : window.location.href.indexOf("cocorobo.hk") != -1
        ? zhHantLanguage.aiCard
        : enLanguage.aiCard;
    if (properties.isLoading) {
      html = `
        <div class="is_show_user" style="${
          properties.is_show_user ? "display:flex;" : "display:none;"
        }">
          <i class="el-icon" style="font-size: 18px;color:#58aba2;width: 18px;height: 27px;" >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="M628.736 528.896A416 416 0 0 1 928 928H96a415.872 415.872 0 0 1 299.264-399.104L512 704zM720 304a208 208 0 1 1-416 0 208 208 0 0 1 416 0"></path></svg>
          </i>
          <div class="is_show_user_name">${properties.user_name}</div>
          <i class="el-icon" style="font-size: 16px;width: 16px;height: 27px;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="m199.04 672.64 193.984 112 224-387.968-193.92-112-224 388.032zm-23.872 60.16 32.896 148.288 144.896-45.696zM455.04 229.248l193.92 112 56.704-98.112-193.984-112-56.64 98.112zM104.32 708.8l384-665.024 304.768 175.936L409.152 884.8h.064l-248.448 78.336zm384 254.272v-64h448v64h-448z"></path></svg></i>
          <div class="apostrophe"></div>
        </div>
        <div class="AI_card" style="height:${
          !properties.is_show_user
            ? "100%"
            : properties.nodeSize && properties.nodeSize.height
            ? properties.nodeSize.height - 27 + "px"
            : 223 + "px"
        };">
            <div class="uml-head" style="background-color:${color};display: flex;flex-direction: row;justify-content: center;">
              <div style="text-align: center;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width:80%;">
                ${properties.topic ? properties.topic : cardTitle}
              </div>
              <i id="${id}" class="el-icon closeImg" style="${
                properties.isShowDelete ? "display:block;font-size: 20px;" : "display:none;font-size: 20px;"
              }"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="m466.752 512-90.496-90.496a32 32 0 0 1 45.248-45.248L512 466.752l90.496-90.496a32 32 0 1 1 45.248 45.248L557.248 512l90.496 90.496a32 32 0 1 1-45.248 45.248L512 557.248l-90.496 90.496a32 32 0 0 1-45.248-45.248z"></path><path fill="currentColor" d="M512 896a384 384 0 1 0 0-768 384 384 0 0 0 0 768m0 64a448 448 0 1 1 0-896 448 448 0 0 1 0 896"></path></svg>
              </i>
            </div>
            <div style="height:${
              !properties.is_show_user
                ? "87%"
                : properties.nodeSize && properties.nodeSize.height
                ? properties.nodeSize.height - 60 + "px"
                : 190 + "px"
            };display: flex;justify-content: center;align-items: center;flex-direction: column;overflow-y: auto;">
                <div style="padding:2px 10px;">
                    <img src="${img_loading}"/>
                </div>
            </div>
        </div>
        `;
    } else {
      if (
        properties.radio == "image" &&
        properties.imgResult &&
        properties.imgResult != ""
      ) {
        html = `
        <div class="is_show_user" style="${
          properties.is_show_user ? "display:flex;" : "display:none;"
        }">
          <i class="el-icon" style="font-size: 18px;color:#58aba2;width: 18px;height: 27px;" >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="M628.736 528.896A416 416 0 0 1 928 928H96a415.872 415.872 0 0 1 299.264-399.104L512 704zM720 304a208 208 0 1 1-416 0 208 208 0 0 1 416 0"></path></svg>
          </i>
          <div class="is_show_user_name">${properties.user_name}</div>
          <i class="el-icon" style="font-size: 16px;width: 16px;height: 27px;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="m199.04 672.64 193.984 112 224-387.968-193.92-112-224 388.032zm-23.872 60.16 32.896 148.288 144.896-45.696zM455.04 229.248l193.92 112 56.704-98.112-193.984-112-56.64 98.112zM104.32 708.8l384-665.024 304.768 175.936L409.152 884.8h.064l-248.448 78.336zm384 254.272v-64h448v64h-448z"></path></svg></i>
          <div class="apostrophe"></div>
        </div>
        <div class="AI_card" style="height:${
          !properties.is_show_user
            ? "100%"
            : properties.nodeSize && properties.nodeSize.height
            ? properties.nodeSize.height - 27 + "px"
            : 223 + "px"
        };">
            <div class="uml-head" style="background-color:${color};display: flex;flex-direction: row;justify-content: center;" title="${
          properties.topic ? properties.topic : cardTitle
        }">
              <div style="text-align: center;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width:80%;">
                ${properties.topic ? properties.topic : cardTitle}
              </div>
              <i id="${id}" class="el-icon closeImg" style="${
                properties.isShowDelete ? "display:block;font-size: 20px;" : "display:none;font-size: 20px;"
              }"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="m466.752 512-90.496-90.496a32 32 0 0 1 45.248-45.248L512 466.752l90.496-90.496a32 32 0 1 1 45.248 45.248L557.248 512l90.496 90.496a32 32 0 1 1-45.248 45.248L512 557.248l-90.496 90.496a32 32 0 0 1-45.248-45.248z"></path><path fill="currentColor" d="M512 896a384 384 0 1 0 0-768 384 384 0 0 0 0 768m0 64a448 448 0 1 1 0-896 448 448 0 0 1 0 896"></path></svg>
              </i>
            </div>
            <div style="height:${
              !properties.is_show_user
                ? "87%"
                : properties.nodeSize && properties.nodeSize.height
                ? properties.nodeSize.height - 60 + "px"
                : 190 + "px"
            };display: flex;justify-content: center;align-items: center;flex-direction: column;overflow-y: auto;">
                <div style="padding:2px 10px;">
                    <img crossorigin="anonymous" draggable="false" style="width: auto;height: auto;max-width: 100%;max-height: 100%;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;" src="${
                      properties.imgResult
                    }"/>
                </div>
            </div>
        </div>
        `;
      } else if (
        properties.radio == "description" &&
        properties.aiContent &&
        properties.aiContent != ""
      ) {
        html = `
        <div class="is_show_user" style="${
          properties.is_show_user ? "display:flex;" : "display:none;"
        }">
          <i class="el-icon" style="font-size: 18px;color:#58aba2;width: 18px;height: 27px;" >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="M628.736 528.896A416 416 0 0 1 928 928H96a415.872 415.872 0 0 1 299.264-399.104L512 704zM720 304a208 208 0 1 1-416 0 208 208 0 0 1 416 0"></path></svg>
          </i>
          <div class="is_show_user_name">${properties.user_name}</div>
          <i class="el-icon" style="font-size: 16px;width: 16px;height: 27px;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="m199.04 672.64 193.984 112 224-387.968-193.92-112-224 388.032zm-23.872 60.16 32.896 148.288 144.896-45.696zM455.04 229.248l193.92 112 56.704-98.112-193.984-112-56.64 98.112zM104.32 708.8l384-665.024 304.768 175.936L409.152 884.8h.064l-248.448 78.336zm384 254.272v-64h448v64h-448z"></path></svg></i>
          <div class="apostrophe"></div>
        </div>
        <div class="AI_card" style="height:${
          !properties.is_show_user
            ? "100%"
            : properties.nodeSize && properties.nodeSize.height
            ? properties.nodeSize.height - 27 + "px"
            : 223 + "px"
        };">
            <div class="uml-head" style="background-color:${color};display: flex;flex-direction: row;justify-content: center;" title="${
          properties.topic ? properties.topic : cardTitle
        }">
              <div style="text-align: center;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width:80%;">
                ${properties.topic ? properties.topic : cardTitle}
              </div>
              <i id="${id}" class="el-icon closeImg" style="${
                properties.isShowDelete ? "display:block;font-size: 20px;" : "display:none;font-size: 20px;"
              }"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="m466.752 512-90.496-90.496a32 32 0 0 1 45.248-45.248L512 466.752l90.496-90.496a32 32 0 1 1 45.248 45.248L557.248 512l90.496 90.496a32 32 0 1 1-45.248 45.248L512 557.248l-90.496 90.496a32 32 0 0 1-45.248-45.248z"></path><path fill="currentColor" d="M512 896a384 384 0 1 0 0-768 384 384 0 0 0 0 768m0 64a448 448 0 1 1 0-896 448 448 0 0 1 0 896"></path></svg>
              </i>
            </div>
            <div style="height:${
              !properties.is_show_user
                ? "87%"
                : properties.nodeSize && properties.nodeSize.height
                ? properties.nodeSize.height - 60 + "px"
                : 190 + "px"
            };display: flex;justify-content: flex-start;align-items: center;flex-direction: column;overflow-y: auto;word-break: break-all;word-wrap: break-word;color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));font-family: SimSun;font-size: 14px;font-style: normal;font-weight: 400;line-height: 22px;padding:16px;">
                <div style="padding:0;">
                    <p style="text-align:justify;margin-top: 0;">${
                      properties.aiContent ? properties.aiContent : ""
                    }</p>
                </div>
            </div>
        </div>
        `;
      } else {
        html = `
        <div class="is_show_user" style="${
          properties.is_show_user ? "display:flex;" : "display:none;"
        }">
          <i class="el-icon" style="font-size: 18px;color:#58aba2;width: 18px;height: 27px;" >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="M628.736 528.896A416 416 0 0 1 928 928H96a415.872 415.872 0 0 1 299.264-399.104L512 704zM720 304a208 208 0 1 1-416 0 208 208 0 0 1 416 0"></path></svg>
          </i>
          <div class="is_show_user_name">${properties.user_name}</div>
          <i class="el-icon" style="font-size: 16px;width: 16px;height: 27px;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="m199.04 672.64 193.984 112 224-387.968-193.92-112-224 388.032zm-23.872 60.16 32.896 148.288 144.896-45.696zM455.04 229.248l193.92 112 56.704-98.112-193.984-112-56.64 98.112zM104.32 708.8l384-665.024 304.768 175.936L409.152 884.8h.064l-248.448 78.336zm384 254.272v-64h448v64h-448z"></path></svg></i>
          <div class="apostrophe"></div>
        </div>
        <div class="AI_card" style="height:${
          !properties.is_show_user
            ? "100%"
            : properties.nodeSize && properties.nodeSize.height
            ? properties.nodeSize.height - 27 + "px"
            : 223 + "px"
        };">
            <div class="uml-head" style="background-color:${color};display: flex;flex-direction: row;justify-content: center;" title="${
          properties.topic ? properties.topic : cardTitle
        }">
              <div style="text-align: center;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width:80%;">
                ${properties.topic ? properties.topic : cardTitle}
              </div>
              <i id="${id}" class="el-icon closeImg" style="${
                properties.isShowDelete ? "display:block;font-size: 20px;" : "display:none;font-size: 20px;"
              }"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="m466.752 512-90.496-90.496a32 32 0 0 1 45.248-45.248L512 466.752l90.496-90.496a32 32 0 1 1 45.248 45.248L557.248 512l90.496 90.496a32 32 0 1 1-45.248 45.248L512 557.248l-90.496 90.496a32 32 0 0 1-45.248-45.248z"></path><path fill="currentColor" d="M512 896a384 384 0 1 0 0-768 384 384 0 0 0 0 768m0 64a448 448 0 1 1 0-896 448 448 0 0 1 0 896"></path></svg>
              </i>
            </div>
            <div style="height:${
              !properties.is_show_user
                ? "87%"
                : properties.nodeSize && properties.nodeSize.height
                ? properties.nodeSize.height - 60 + "px"
                : 190 + "px"
            };display: flex;justify-content: flex-start;align-items: center;flex-direction: column;overflow-y: auto;">
                <div style="padding:2px 10px;">
                    <p style="text-align:justify;margin-top: 0;"></p>
                </div>
            </div>
        </div>
        `;
      }
    }
    el.innerHTML = html;
    // 需要先把之前渲染的子节点清除掉。
    rootEl.innerHTML = "";
    rootEl.appendChild(el);

    let that = this;
    if (document.getElementById(id)) {
      document.getElementById(id).addEventListener("click", (event) => {
        let tip18 =
          window.location.href.indexOf("cocorobo.cn") != -1
            ? zhHansLanguage.tip18
            : window.location.href.indexOf("cocorobo.hk") != -1
            ? zhHantLanguage.tip18
            : enLanguage.tip18;
        let tip2 =
          window.location.href.indexOf("cocorobo.cn") != -1
            ? zhHansLanguage.tip2
            : window.location.href.indexOf("cocorobo.hk") != -1
            ? zhHantLanguage.tip2
            : enLanguage.tip2;
        let confirm =
          window.location.href.indexOf("cocorobo.cn") != -1
            ? zhHansLanguage.confirm
            : window.location.href.indexOf("cocorobo.hk") != -1
            ? zhHantLanguage.confirm
            : enLanguage.confirm;
        let cancel =
          window.location.href.indexOf("cocorobo.cn") != -1
            ? zhHansLanguage.cancel
            : window.location.href.indexOf("cocorobo.hk") != -1
            ? zhHantLanguage.cancel
            : enLanguage.cancel;
        let nodes = [];
        nodes.push(that.props.graphModel.getElement(id).getData());

        MessageBox.confirm(tip18, tip2, {
          confirmButtonText: confirm,
          cancelButtonText: cancel,
          type: "warning",
        })
          .then(() => {
            let newEdges = [];
            let outgoingEdgeModel =
              that.props.graphModel.getNodeOutgoingEdge(id);
            if (outgoingEdgeModel.length != 0) {
              for (let j = 0; j < outgoingEdgeModel.length; j++) {
                let edge = that.props.graphModel.getEdgeModelById(
                  outgoingEdgeModel[j].id
                );
                newEdges.push(edge.getData());
              }
            }
            let incomingEdgeModel =
              that.props.graphModel.getNodeIncomingEdge(id);
            if (incomingEdgeModel.length != 0) {
              for (let j = 0; j < incomingEdgeModel.length; j++) {
                let edge = that.props.graphModel.getEdgeModelById(
                  incomingEdgeModel[j].id
                );
                newEdges.push(edge.getData());
              }
            }
            sendUpdate(nodes, newEdges, "delete", 0);
            that.props.graphModel.deleteNode(id);

            that.props.graphModel.clearSelectElements();
            let newData = {
              nodes: nodes,
              edges: newEdges,
              backgroundUrl: localStorage.getItem("background"),
            };
            add_Operation(newData, "deleteNode");
          })
          .catch(() => {
            // this.$message({
            //   type: 'info',
            //   message: '已取消删除'
            // });
          });
        event.stopPropagation();
      });
    }

    // const textTips = properties.aiContent ? properties.aiContent : '';
    // //添加自定义属性 target-title
    // //itemContainer表示，需要在哪个DOM下增加title（自定义属性）
    // el.setAttribute("target-title", textTips);
    // //判断这个DOM节点是否包含这个自定义属性，
    // if (el.getAttributeNode("target-title")) {
    //   //有的话直接调用函数。（用于多个相同DOM命名，统一添加，）
    //   const textTips = el.getAttribute('target-title');
    //   //如果用的地方比较少，不需要多个添加，可以直接调用函数
    //   //itemContainer 表示，需要在哪个DOM下增加title
    //   //textTips  需要展示的title文本
    //   this.contentTitle(el, textTips, properties.topic, this.props, properties.isShowDescribe ? properties.isShowDescribe : false);
    // }
  }
}

export default {
  type: "icon-AI",
  view: AICardNode,
  model: AICardModel,
};
