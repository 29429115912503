import { HtmlNodeModel, HtmlNode } from "@logicflow/core";
import "../../../uml.css";
import $ from "jquery";
import { zhHansLanguage } from "../../../lang/zh-Hans.js";
import { zhHantLanguage } from "../../../lang/zh-Hant.js";
import { enLanguage } from "../../../lang/en.js";
import { sendUpdate, add_Operation } from "../../../tools/send.js";
import { ElMessageBox as MessageBox } from "element-plus";

class ThemeModel extends HtmlNodeModel {
  initNodeData(data) {
    super.initNodeData(data);
    this.text.editable = false; // 禁止节点文本编辑
    const width = 250;
    const height = 75;
    this.width = width;
    this.height = height;
    this.resizable = false;
    const unable_to_connect_with_oneself = {
      message:
        window.location.href.indexOf("cocorobo.cn") != -1
          ? zhHansLanguage.tip43
          : window.location.href.indexOf("cocorobo.hk") != -1
          ? zhHantLanguage.tip43
          : zhHansLanguage.tip43,
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceAnchor, targetAnchor);
        return targetNode.id != sourceNode.id;
      },
    };
    this.sourceRules.push(unable_to_connect_with_oneself);
    const unable_to_connect_with_theme = {
      message:
        window.location.href.indexOf("cocorobo.cn") != -1
          ? zhHansLanguage.unable_to_connect_with_theme
          : window.location.href.indexOf("cocorobo.hk") != -1
          ? zhHantLanguage.unable_to_connect_with_theme
          : zhHansLanguage.unable_to_connect_with_theme,
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceNode, sourceAnchor, targetAnchor);
        return targetNode.type != "icon-theme";
      },
    };
    this.sourceRules.push(unable_to_connect_with_theme);
    const unable_to_connect_with_other = {
      message:
        window.location.href.indexOf("cocorobo.cn") != -1
          ? zhHansLanguage.unable_to_connect_with_other
          : window.location.href.indexOf("cocorobo.hk") != -1
          ? zhHantLanguage.unable_to_connect_with_other
          : zhHansLanguage.unable_to_connect_with_other,
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceAnchor, targetAnchor);
        let edges = this.graphModel.getNodeOutgoingEdge(sourceNode.id);
        let isConnect = false;
        for (let i = 0; i < edges.length; i++) {
          if (
            edges[i].sourceNodeId == sourceNode.id &&
            edges[i].targetNodeId == targetNode.id
          ) {
            isConnect = true;
            break;
          }
        }
        return isConnect == false;
      },
    };
    this.sourceRules.push(unable_to_connect_with_other);
  }
}
class ThemeNode extends HtmlNode {
  setHtml(rootEl) {
    const { id, properties } = this.props.model;

    // var r = Math.floor(Math.random() * 256);
    // var g = Math.floor(Math.random() * 256);
    // var b = Math.floor(Math.random() * 256);
    // var color = "rgb(" + r + "," + g + "," + b + ")";
    var color = "var(--brand-normal, #3681FC)";
    var fontColor = properties.fontColor ? properties.fontColor : "#FFF";

    const el = document.createElement("div");
    el.className = "uml-wrapper";
    el.id = "theme_" + id;
    if (properties.backgroundColor && !properties.isGradientColor) {
      el.style =
        "background: var(--brand-normal, " + properties.backgroundColor + ");";
      color = "var(--brand-normal, " + properties.backgroundColor + ")";
    } else if (
      properties.backgroundColor &&
      !properties.gradientColor &&
      properties.isGradientColor
    ) {
      el.style =
        "background: var(--brand-normal, " + properties.backgroundColor + ");";
      color = "var(--brand-normal, " + properties.backgroundColor + ")";
    } else if (
      properties.backgroundColor &&
      properties.gradientColor &&
      properties.isGradientColor
    ) {
      el.style =
        "background: linear-gradient(to right, " +
        properties.backgroundColor +
        ", " +
        properties.gradientColor +
        ");";
      color =
        "linear-gradient(to right, " +
        properties.backgroundColor +
        ", " +
        properties.gradientColor +
        ")";
    } else {
      el.style = "background: var(--brand-normal, #3681FC);";
      color = "var(--brand-normal, #3681FC)";
    }

    var html = `
            <div class="is_show_user" style="${
              properties.is_show_user
                ? "display:flex;background: #ffffff;"
                : "display:none;"
            }">
                <i class="el-icon" style="font-size: 18px;color:#58aba2;width: 18px;height: 27px;" >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="M628.736 528.896A416 416 0 0 1 928 928H96a415.872 415.872 0 0 1 299.264-399.104L512 704zM720 304a208 208 0 1 1-416 0 208 208 0 0 1 416 0"></path></svg>
                </i>
                <div class="is_show_user_name">${properties.user_name}</div>
                <i class="el-icon" style="font-size: 16px;width: 16px;height: 27px;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="m199.04 672.64 193.984 112 224-387.968-193.92-112-224 388.032zm-23.872 60.16 32.896 148.288 144.896-45.696zM455.04 229.248l193.92 112 56.704-98.112-193.984-112-56.64 98.112zM104.32 708.8l384-665.024 304.768 175.936L409.152 884.8h.064l-248.448 78.336zm384 254.272v-64h448v64h-448z"></path></svg></i>
                <div class="apostrophe"></div>
            </div>
            <div style="${
              properties.is_show_user ? "height:48px;" : "height:75px;"
            }">
            <i id="${id}" class="el-icon closeImgTheme" style="${
              properties.isShowDelete ? "display:block;font-size: 20px;" : "display:none;font-size: 20px;"
            }"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="m466.752 512-90.496-90.496a32 32 0 0 1 45.248-45.248L512 466.752l90.496-90.496a32 32 0 1 1 45.248 45.248L557.248 512l90.496 90.496a32 32 0 1 1-45.248 45.248L512 557.248l-90.496 90.496a32 32 0 0 1-45.248-45.248z"></path><path fill="currentColor" d="M512 896a384 384 0 1 0 0-768 384 384 0 0 0 0 768m0 64a448 448 0 1 1 0-896 448 448 0 0 1 0 896"></path></svg>
            </i>
            <div style="height:100%;display: flex;justify-content: center;align-items: center;flex-direction: column;">
                <div class="uml-select-body" style="text-align: center;display: flex;flex-direction: row;justify-content: center;align-items: center;height:100%;width: 100%;padding:0;">
                    <div class="el-input" style="width: 100%;">
                        <span data-placeholder="${
                          window.location.href.indexOf("cocorobo.cn") != -1
                            ? zhHansLanguage.themeDesc
                            : window.location.href.indexOf("cocorobo.hk") != -1
                            ? zhHantLanguage.themeDesc
                            : enLanguage.themeDesc
                        }" class="placeholder" id="text_${
      this.props.model.id
    }" style="width: 100%;background: ${color};display: block;color:${fontColor};font-family: SimSun;font-size: 16px;font-style: normal;font-weight: 500;line-height: normal;">${
      properties.topic ? properties.topic : ""
    }</span>
                        <input type="text" autocomplete="off" class="el-input__inner" id="input_${
                          this.props.model.id
                        }"
                        placeholder="${
                          window.location.href.indexOf("cocorobo.cn") != -1
                            ? zhHansLanguage.themeDesc
                            : window.location.href.indexOf("cocorobo.hk") != -1
                            ? zhHantLanguage.themeDesc
                            : enLanguage.themeDesc
                        }" 
                        style="background: ${color};text-align: center;border:none;display:none;color: ${fontColor};font-family: SimSun;font-size: 16px;font-style: normal;font-weight: 500;line-height: normal;" value="${
      properties.topic ? properties.topic : ""
    }"/>
                    </div>
                </div>
            </div>
            </div>
            `;
    el.innerHTML = html;
    // 需要先把之前渲染的子节点清除掉。
    rootEl.innerHTML = "";
    rootEl.appendChild(el);
    $("#theme_" + id)
      .parent()
      .css("box-shadow", "none");
    let that = this;
    if (document.getElementById(id)) {
      document.getElementById(id).addEventListener("click", (event) => {
        let tip18 =
          window.location.href.indexOf("cocorobo.cn") != -1
            ? zhHansLanguage.tip18
            : window.location.href.indexOf("cocorobo.hk") != -1
            ? zhHantLanguage.tip18
            : enLanguage.tip18;
        let tip2 =
          window.location.href.indexOf("cocorobo.cn") != -1
            ? zhHansLanguage.tip2
            : window.location.href.indexOf("cocorobo.hk") != -1
            ? zhHantLanguage.tip2
            : enLanguage.tip2;
        let confirm =
          window.location.href.indexOf("cocorobo.cn") != -1
            ? zhHansLanguage.confirm
            : window.location.href.indexOf("cocorobo.hk") != -1
            ? zhHantLanguage.confirm
            : enLanguage.confirm;
        let cancel =
          window.location.href.indexOf("cocorobo.cn") != -1
            ? zhHansLanguage.cancel
            : window.location.href.indexOf("cocorobo.hk") != -1
            ? zhHantLanguage.cancel
            : enLanguage.cancel;
        let nodes = [];
        nodes.push(that.props.graphModel.getElement(id).getData());

        MessageBox.confirm(tip18, tip2, {
          confirmButtonText: confirm,
          cancelButtonText: cancel,
          type: "warning",
        })
          .then(() => {
            let newEdges = [];
            let outgoingEdgeModel =
              that.props.graphModel.getNodeOutgoingEdge(id);
            if (outgoingEdgeModel.length != 0) {
              for (let j = 0; j < outgoingEdgeModel.length; j++) {
                let edge = that.props.graphModel.getEdgeModelById(
                  outgoingEdgeModel[j].id
                );
                newEdges.push(edge.getData());
              }
            }
            let incomingEdgeModel =
              that.props.graphModel.getNodeIncomingEdge(id);
            if (incomingEdgeModel.length != 0) {
              for (let j = 0; j < incomingEdgeModel.length; j++) {
                let edge = that.props.graphModel.getEdgeModelById(
                  incomingEdgeModel[j].id
                );
                newEdges.push(edge.getData());
              }
            }
            sendUpdate(nodes, newEdges, "delete", 0);
            that.props.graphModel.deleteNode(id);

            that.props.graphModel.clearSelectElements();
            let newData = {
              nodes: nodes,
              edges: newEdges,
              backgroundUrl: localStorage.getItem("background"),
            };
            add_Operation(newData, "deleteNode");
          })
          .catch(() => {
            // this.$message({
            //   type: 'info',
            //   message: '已取消删除'
            // });
          });
        event.stopPropagation();
      });
    }
    $("#input_" + that.props.model.id).on("change", function (e) {
      that.props.graphModel
        .getNodeModelById(that.props.model.id)
        .setProperties({
          topic: e.currentTarget.value,
        });
      let nodes = [];
      nodes.push(
        that.props.graphModel.getNodeModelById(that.props.model.id).getData()
      );
      sendUpdate(nodes, [], "update", 0);
      let newData = {
        nodes: nodes,
        edges: [],
        backgroundUrl: localStorage.getItem("background"),
      };
      add_Operation(newData, "updateNode");
    });
    document.documentElement.style.setProperty("--data-color", fontColor);
  }
}

export default {
  type: "icon-theme",
  view: ThemeNode,
  model: ThemeModel,
};
